import React from 'react'
import '../testimonial.css'
import sunitajain from '../sunitajain.jpeg'
import monica from '../Monica.jpeg'
import { HashLink as Link } from 'react-router-hash-link';

function Testimonial() {
    return (
        <>
            <section className='testimonial-section'>

                <div className="container">
                    <div className="testimonial">
                        <h1>Personal Journeys</h1>
                        <p className='text-center offering-title'>How Natural Living Has Supported Real Transformation</p>

                        <div className="all-testimonial">
                            <div className="testimonial-dtail">
                                <div className="testimonial-image">
                                    <Link to="/success#prgati" smooth>
                                        <img src="images/prgati_jain.png" alt="" />
                                    </Link>

                                </div>
                                <Link to="/success#prgati" smooth>
                                    <div className="testimonial-text">
                                        <h5 className='fw-bold text-start mb-4 mt-3'>Dr. Pragati Jain, Founder Anuyog</h5>
                                        <p>With a few simple, nature-based lifestyle changes, I lost weight from 62 kg to 56 kg—completely side-effect-free. It’s amazing how natural choices can improve our health.</p>
                                    </div>
                                </Link>

                                <Link to="/success#prgati" smooth>
                                    <button type="button" className="btn">View Story</button>
                                </Link>
                            </div>
                            <div className="testimonial-dtail">
                                <div className="testimonial-image">
                                    <Link to="/success#monica" smooth>
                                        <img src={monica} alt="" />
                                    </Link>

                                </div>
                                <Link to="/success#monica" smooth>
                                    <div className="testimonial-text">
                                        <h5 className='fw-bold text-start mb-4 mt-3'>Monika Jain "Mumukshu"  Shahgarh
                                        </h5>
                                        <p>Naturopathy has been a game-changer in managing my health condition. I've found relief through natural methods that truly respect my body.</p>
                                    </div>
                                </Link>

                                <Link to="/success#monica" smooth>
                                    <button type="button" className="btn">View Story</button>
                                </Link>
                            </div>
                            <div className="testimonial-dtail">
                                <div className="testimonial-image">
                                    <Link to="/success#sunita" smooth>
                                        <img src={sunitajain} alt="" />
                                    </Link>


                                </div>
                                <Link to="/success#sunita" smooth>
                                    <div className="testimonial-text">
                                        <h5 className='fw-bold text-start mb-4 mt-3'> Sunita Jain, Sagar </h5>
                                        <p>Living with an autoimmune condition like HLA-B27 felt overwhelming until I found a natural path to relief. Through naturopathy, acupressure, and Sujok therapy.</p>
                                    </div>
                                </Link>


                                <Link to="/success#sunita" smooth>
                                    <button type="button" className="btn">View Story</button>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Testimonial
