import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../workshopdetails.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

function WorkshopDetails() {
 

  

    const location = useLocation();
    const navigate = useNavigate();
    const { workshop } = location.state || {};

    useEffect(() => {
        AOS.init({ duration: 1500, easing: 'ease-in-out' });
      }, []);

    if (!workshop) {

        return (
            <div>
                <h2>No workshop details available. Please go back and try again.</h2>
                <button onClick={() => navigate('/')}>Go Back</button>
            </div>
        );
    }

    return (


        <>
            

            <section className='workshopdtails-section'>
                <div className="container">
                    <div className='workshop-details'>
                        <h1 className='mb-5'>{workshop.title}</h1>
                        <img src={`https://efsouls.pythonanywhere.com/${workshop.image}`} alt={workshop.title} data-aos="zoom-in-up" />
                        <p className='mt-4 fw-bold fs-5 text-center'>{workshop.description}</p>
                        <h5><strong>Date:</strong> {workshop.date}</h5>
                        <h5><strong>Time:</strong> {workshop.start_time} - {workshop.end_time}</h5>
                        <h5><strong>Fees: $</strong>{workshop.fees}</h5>
                        <h5><strong>Language:</strong> {workshop.language}</h5>
                    </div>
                </div>
            </section>

        </>
    );
}

export default WorkshopDetails;
