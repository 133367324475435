import React from 'react'
import '../consultancy.css'
// import dignostic from '../dignostic.jpg'
import yoga_ico from '../yoga_ico.png'
import element from '../elements.png'
import meditation from '../meditation.png'
import fresh from '../fresh-produce.png'
import eco from '../eco-house.png'
import dsjain from '../dsjain.png'
import sunitajain from '../sunitajain.jpeg'

import dajain from '../dajain.jpeg'
import drjain from '../drjain.jpeg'

import '../profile.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState, useRef } from 'react'
// import why from '../why.jpg'
// import context from '../context.jpg'
// import expert from '../expert.jpg'
// import natural from '../natural.jpg'
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { NavLink } from 'react-router-dom';
// import how from '../how.mp4'
import conce from '../consultancy.png'
import call from '../how-call.jpg'
import how_expert from '../how_expert.jpg'
import inspire from '../how_inspire.jpg'
import stratagey from '../how_stratagey.jpg'
import progress from '../how_progress.jpg'
import CountUp from 'react-countup';
import Testimonial from './Testimonial'



function Counter({ end, duration }) {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        // Initialize AOS
        AOS.init({ duration: 1500, easing: 'ease-in-out' });

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(ref.current);
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                // eslint-disable-next-line 
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div ref={ref}>
            {isVisible ? (
                <CountUp end={end} duration={duration} separator="," suffix="+" />
            ) : (
                <span>0+</span>
            )}
        </div>
    );
}


function Consultency() {
    // const testimonialsData = [
    //     {
    //         imgSrc: '/images/dpjain.png',
    //         message: 'With a few simple, nature-based lifestyle changes, I lost weight from 62 kg to 56 kg—completely side-effect-free. It’s amazing how natural choices can improve our health.',
    //         name: 'Dr. Pragati Jain, Founder Anuyog, Bangalore',
    //     },
    //     {
    //         imgSrc: '/images/dpjain.png',
    //         message: 'Naturopathy has been a game-changer in managing my health condition. . I ve found relief through natural methods  that truly respect my body.',
    //         name: 'Monika Jain "Mumukshu"  Shahgarh'
    //     },
    //     {
    //         imgSrc: '/images/dpjain.png',
    //         message: 'Living with an autoimmune condition like HLA-B27 felt overwhelming until I found a natural path to relief. Through naturopathy, acupressure, and Sujok therapy, I’ve gained remarkable control over my health without the burden of medications.',
    //         name: 'Sunita Jain, House Wife, Sagar '
    //     },

    // ];








    useEffect(() => {
        AOS.init({ duration: 1500, easing: 'ease-in-out' });
    }, []);

    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //     pauseOnHover: true,
    // };

    return (
        <>
        {/* <strong style={{ color: '#9D3B24', textShadow: '1px 1px 1px white' }}></strong> */}
            <section className='consultancy-section'>
                <div className="consultancy">

                    <img src={conce} alt="" />

                    <h1 className='consultancy-heading  '>PERSONALIZED GUIDANCE ON  NATURAL LIVING   <br /> TO INCREASE YOUR HOLISTIC WELLBEING  </h1>


                </div>
            </section>








            <section className='work-section '>
                <div className="container">
                    <div className="work">


                        <div className="work-text" >
                            <h3
                                className='fonts text-center text-dark mb-4 fw-bold offering-title'
                                style={{ maxWidth: '730px', margin: '0 auto' }}
                            >
                                <strong style={{ color: '#9D3B24' }}>Our goal</strong> is to empower your journey toward
                                <strong style={{ color: '#9D3B24' }}> natural living</strong> with
                                <strong style={{ color: '#9D3B24' }}> personalized recommendations from experts</strong>
                            </h3>

                            <div className=" living-text col-md-6">
                                <h4 className='fw-bold'>Why personalized recommendations?</h4>
                                <p >Because no two individuals are the same. Each person is shaped by a unique combination of lifestyle, geography, societal influences, and personal preferences. At Anuyog, we believe in breaking free from the 'one-size-fits-all' mindset. Instead, we offer a personalized approach to natural living that truly honors your individuality. </p>
                            </div>



                        </div>

                    </div>
                </div>
            </section>

            <section className='pillar-section'>
                <div className="container">
                    <div className="pillar">
                        <h2 className='mb-4'><strong style={{ color: '#9D3B24' }}>Key Focus Areas of Anuyog Consultancy</strong></h2>
                        <h4 className='mb-5 offering-title'>We provide <strong style={{ color: '#9D3B24' }}>specialized consultancy </strong>in key areas, helping you <strong style={{ color: '#9D3B24' }}>discover a natural, sustainable holistic lifestyle </strong> tailored to your unique path</h4>

                    </div>
                    <div className="all-pillar">
                        <div className="pillar-detail">
                            <div className="pillar-image">

                                <img src={yoga_ico} alt="" />
                            </div>
                            <div className="pillar-text">
                                <h5 className='fw-bold'>Mindful Diet of Food and Thoughts  </h5>
                            </div>
                        </div>

                        <div className="pillar-detail">
                            <div className="pillar-image">
                                <div>
                                    <img src={element} alt="" />
                                </div>
                            </div>
                            <div className="pillar-text">
                                <h5 className='fw-bold'>Healing with Nature's Five Elements
                                </h5>
                            </div>
                        </div>
                        <div className="pillar-detail">
                            <div className="pillar-image">

                                <img src={meditation} alt="" />

                            </div>
                            <div className="pillar-text">
                                <h5 className='fw-bold'>Wellness with Yog and Pranayama
                                </h5>
                            </div>
                        </div>
                        <div className="pillar-detail">
                            <div className="pillar-image">
                                <div>
                                    <img src={fresh} alt="" />
                                </div>
                            </div>
                            <div className="pillar-text">
                                <h5 className='fw-bold'>Cultivate Fresh, Organic Produce</h5>
                            </div>
                        </div>
                        <div className="pillar-detail">
                            <div className="pillar-image">
                                <img src={eco} alt="" />
                            </div>
                            <div className="pillar-text">
                                <h5 className='fw-bold'>Build Eco-friendly Mud Homes</h5>
                            </div>
                        </div>
                    </div>
                    <h4 className='mt-4'><strong style={{ color: '#9D3B24' }}>Join our consultancy program to transform your lifestyle</strong></h4>
                    <h5>into one that's more balanced, fulfilling, and meaningful.</h5>
                </div>

            </section>

            {/* <section className='how_works-section'>
                <div className="container">
                    <div className="how">
                        <div className="how-image">

                            <div className="how_works-container">
                                <video
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    className="background-video"
                                >
                                    <source src={how} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>






                            <NavLink
                                to="/schedule"
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <button type="button" className="btn">Book a Free Discovery Call</button>
                            </NavLink>

                        </div>
                    </div>
                </div>

            </section> */}

            <section className='steps-working-section'>
                <div className="container">
                    <h1 className='fw-bold'>How Anuyog Consultancy Works</h1>
                    <div className="consultancy-step">
                    <div className="all-working">
                        <div className="how_works_image">
                            <img src={call} alt="" />
                        </div>
                        <div className="how_works-text">
                            <h4 className="fw-bold">Step 1:</h4>
                            <p> Book a free discovery call where we’ll explore your unique requirements, preferences, and goals for natural living.</p>
                        </div>
                    </div>
                    <div className="all-working demo">
                        <div className="how_works_image">
                            <img src={how_expert} alt="" />
                        </div>
                        <div className="how_works-text">
                            <h4 className="fw-bold">Step 2:</h4>
                            <p>Our experts will review your query, and we'll outline the next steps along with the consultancy fee. Any adjustments will be communicated as your journey evolves.</p>
                        </div>
                    </div>
                    <div className="all-working">
                        <div className="how_works_image">
                            <img src={stratagey} alt="" />
                        </div>
                        <div className="how_works-text">
                            <h4 className="fw-bold">Step 3:</h4>
                            <p> You’ll be provided with personalized recommendations,strategies,sustainable plans, and tailored guidance from experts to support your goals.</p>
                        </div>
                    </div>
                    <div className="all-working">
                        <div className="how_works_image">
                            <img src={progress} alt="" />
                        </div>
                        <div className="how_works-text  ">
                            <h4 className="fw-bold">Step 4:</h4>
                            <p>Regular check-ins to monitor your progress, address challenges, and refine your plan as necessary.</p>
                        </div>
                    </div>
                    <div className="all-working ">
                        <div className="how_works_image">
                            <img src={inspire} alt="" />
                        </div>
                        <div className="how_works-text ">
                            <h4 className="fw-bold">Step 5:</h4>
                            <p> Reflect on your transformation and share your experience with the Anuyog Community to inspire others.</p>
                        </div>
                    </div>
                    </div>

                    <NavLink
                                to="/schedule"
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <button type="button" className="btn">Book a Free Discovery Call</button>
                            </NavLink>
                </div>
            </section>






            {/* <section className='why-section section-gapping'>

                <div className="why">

                    <div className="row align-items-center mt-5">
                        <div className="why-image col-6 " data-aos="fade-right">
                            <img src={why} alt="" />
                        </div>

                        <div className="work-text col-md-6" data-aos="fade-left">
                            <h1 className='mb-3 fw-bold'> Individual Factors</h1>
                            <p className='fs-5'><strong>Health Profiles:  </strong>Personalized advice considers unique health histories and current statuses for
                                effective solutions.</p>
                            <p className='fs-5'><strong>Lifestyle:</strong>Tailored recommendations fit seamlessly into individual daily routines.</p>
                        </div>
                    </div>

                </div>

            </section>

            <section className='work-section '>
                <div className="container">
                    <div className="work">

                        <div className="row align-items-center mt-5">
                            <div className="why-image col-6" data-aos="fade-right">
                                <img src={context} alt="" />
                            </div>
                            <div className="work-text col-md-6" data-aos="fade-left">
                                <h1 className='mb-3 fw-bold'>Contextual Factors </h1>
                                <p className='fs-5'><strong>Environment: </strong>Considers the impact of living conditions and suggests improvements. </p>
                                <p className='fs-5'><strong> Cultural and Social Context: </strong> Respects cultural and social influences for more acceptable advice. </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className='why-section section-gapping'>
                <div className="container">
                    <div className="why">

                        <div className="row align-items-center mt-5">
                            <div className="why-image col-6" data-aos="fade-right">
                                <img src="https://kshemavana.com/wp-content/uploads/2022/04/Yoga-Therapy.png" alt="" />
                            </div>
                            <div className="work-text col-md-6" data-aos="fade-left">
                                <h1 className='mb-3 fw-bold'> Moving Beyond ‘One-Size-Fits-All’: </h1>
                                <p className='fs-5'> Customized solutions outperform generic advice, leading to lasting benefits. </p>
                                <p className='fs-5'>  Empowers individuals to make informed health decisions.  </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className='work-section '>
                <div className="container">
                    <div className="work">

                        <div className="row align-items-center mt-5">
                            <div className="why-image col-6" data-aos="fade-right">
                                <img src={expert} alt="" />
                            </div>
                            <div className="work-text col-md-6" data-aos="fade-left">
                                <h1 className='mb-3 fw-bold'>Expert and Specialized Support</h1>
                                <p className='fs-5'><strong> Naturopathy Doctors:</strong> Holistic treatments tailored to your health.</p>
                                <p className='fs-5'><strong> Hypnotherapists: </strong> management and habit change support. </p>
                                <p className='fs-5'><strong>  Yogacharyas: </strong> Personalized yoga practices. </p>
                                <p className='fs-5'><strong> Scientists:</strong> Evidence-based natural living tips.</p>
                                <p className='fs-5'><strong>  Eco-Friendly Architects:</strong> Healthy, sustainable living spaces. </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className='why-section section-gapping'>
                <div className="container">
                    <div className="why">

                        <div className="row align-items-center mt-5">
                            <div className="why-image col-6" data-aos="fade-right">
                                <img src={natural} alt="" />
                            </div>
                            <div className="work-text col-md-6" data-aos="fade-left">
                                <h1 className='mb-3 fw-bold'> Authentic Natural Living:</h1>
                                <p className='fs-5'>In today’s fast-paced world, reconnecting with nature is more important than ever. Authentic Natural Living is about embracing simplicity, sustainability, and a deeper connection to the world around us. It’s about making conscious choices that honor the earth, our well-being, and the harmony between the two. </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section> */}








            <section className='profile-section'>
                <div className="container">
                    <div className="profile">
                        <h1>Our Team</h1>
                        <p className='mb-4'>We collaborate with experienced experts who bring personalized insights and guidance to elevate your health and well-being, ensuring tailored approaches that meet your unique needs for a natural, balanced, and holistic lifestyle.
                        </p>
                    </div>

                    <div className="all-experts row">

                        <div className="experts-detail col-md-4 ">
                            <div className="card" style={{ width: "18rem" }}>
                                <NavLink to="/expert/dr-suresh-jain" onClick={() => {
                                    window.scrollTo(0, 0);
                                }}><img src={dsjain} alt="" className="card-img-top" /></NavLink>
                                <div className="card-body">
                                    <h5 className='text-start fw-bold mb-3'>
                                        <NavLink to="/expert/dr-suresh-jain" onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}>Dr. Suresh Jain</NavLink>
                                    </h5>
                                    <ul>
                                        <li>  <p className="card-text"> Certified Naturopathy Doctor (NDDY)</p></li>
                                        <li> <p className="card-text"> <strong>25 years</strong> of experience in Natural Healing</p></li>
                                    </ul>


                                </div>
                            </div>

                        </div>
                        <div className="experts-detail col-md-4  ">
                            <div className="card" style={{ width: "18rem" }}>
                                <NavLink to="/expert/dr-ashish-jain" onClick={() => {
                                    window.scrollTo(0, 0);
                                }}><img src={dajain} alt="" className="card-img-top" /></NavLink>
                                <div className="card-body">
                                    <h5 className='text-start fw-bold mb-3'>
                                        <NavLink to="/expert/dr-ashish-jain" onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}>Yogacharya Dr. Ashish Jain</NavLink>
                                    </h5>
                                    <ul>
                                    <li><p className="card-text"><strong>Certified Yoga Teacher
                                    </strong></p></li>
                                        <li><p className="card-text"> <strong>10 years</strong> of experience in teaching Yoga</p></li>
                                        <li><p className="card-text"> Head of Balak Vidyarthi Grah, Amayan Bhind</p></li>

                                    </ul>


                                </div>
                            </div>

                        </div>
                        <div className="experts-detail col-md-4 ">
                            <div className="card" style={{ width: "18rem" }}>
                                <NavLink to="/expert/sunita-jain" onClick={() => {
                                    window.scrollTo(0, 0);
                                }}><img src={sunitajain} alt="" className="card-img-top" /></NavLink>
                                <div className="card-body">
                                    <h5 className='text-start fw-bold mb-3'>
                                        <NavLink to="/expert/sunita-jain" onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}>Yogacharya Sunita Jain</NavLink>
                                    </h5>
                                    <ul>
                                    <li>  <p className="card-text"><strong>Certified Yoga Teacher
                                    </strong></p></li>
                                    <li> <p className="card-text"> <strong>20 years</strong> of experience in  Acupressure, Sujok, Magnet Therapy, Yoga, Pranayama & Naturopathy
                                    </p></li>
                                    </ul>


                                </div>
                            </div>

                        </div>
                        <div className="experts-detail col-md-4 ">
                            <div className="card" style={{ width: "18rem" }}>
                                <NavLink to="/expert/rohit-jain" onClick={() => {
                                    window.scrollTo(0, 0);
                                }}><img src={drjain} alt="" className="card-img-top" /></NavLink>
                                <div className="card-body">
                                    <h5 className='text-start fw-bold mb-3'>
                                        <NavLink to="/expert/rohit-jain" onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}> Rohit Jain</NavLink>
                                    </h5>
                                    <ul>
                                        <li> <p className="card-text">Founder, <strong>Banyan Roots Organics Pvt. Ltd.</strong></p></li>
                                        <li> <p className="card-text"><strong>Sustainable Mud House Consultant</strong></p></li>
                                        <li><p className="card-text"><strong>14 years </strong> of experience as an Organic Farmpreneur</p></li>

                                    </ul>



                                </div>
                            </div>

                        </div>
                        <div className="experts-detail col-md-4 ">
                            <div className="card" style={{ width: "18rem" }}>
                                <NavLink to="/expert/dr-pragati-jain" onClick={() => {
                                    window.scrollTo(0, 0);
                                }}><img src='/images/prgati_jain.png' className="card-img-top" alt="" /></NavLink>
                                <div className="card-body">
                                    <h5 className='text-start fw-bold mb-3'>
                                        <NavLink to="/expert/dr-pragati-jain" onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}>Dr. Pragati Jain</NavLink>
                                    </h5>
                                    <ul>
                                        <li><p className="card-text"><strong>Founder, Anuyog</strong></p></li>
                                        <li> <p className="card-text">Ph.D in <strong>Computational Neuroscience</strong></p></li>
                                        <li><p className="card-text"><strong>15 years</strong> of experience in Artificial Intelligence</p></li>
                                    </ul>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className='impact-section'>
                <div className="container">
                    <div className="impact">
                        <h1>Our Team's Impact So Far</h1>
                        <div className="impact-detail">


                            <div className="impact-details">
                                <h1><Counter end={1000} duration={2.5} /></h1>
                                <p>personalized Natural Living recommendations</p>
                            </div>
                            <div className="impact-details">
                                <h1><Counter end={1000} duration={2.5} /></h1>
                                <p>trained in Yog & Pranayama </p>
                            </div>
                            <div className="impact-details">
                                <h1><Counter end={10000} duration={2.5} /></h1>
                                <p> trained in Organic produce</p>
                            </div>
                            <div className="impact-details">
                                <h1><Counter end={100} duration={2.5} /></h1>
                                <p> trained in Eco-friendly Construction </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>







            {/* <section className='dignostic-section'>
                <div className="container">
                    <div className="dignostic">
                        <h1 className='text-center mb-3 fw-bold'>Our Approach to <strong>Holistic Health</strong></h1>
                        <p className='text-venter mb-5 fw-bold text-center'> Tailored, nature-inspired, and non-violent health recommendations focused on holistic well-being.</p>
                        <div className="row ">
                            <div className="work-text col-md-6" data-aos="fade-right">
                                <h1 className='mb-4 fw-bold'>Our Approch</h1>
                                <p className='mb-4'>At Anuyog, we believe in a transformative approach to health that honors the unique needs of every individual. Our philosophy is centered around providing tailored, nature-inspired, and non-violent health recommendations designed to promote holistic well-being. Here’s how we bring this approach to life:</p>
                                <p>We recognize that each person is unique, with their own set of health profiles, lifestyles, and preferences. Our personalized health assessments allow us to craft specific recommendations that seamlessly fit into your daily life. Whether you’re seeking dietary adjustments, lifestyle changes, or specific wellness strategies, our focus is on solutions that resonate with you.</p>

                            </div>
                            <div className=" dignostic-image col-md-6" data-aos="fade-left">
                                <img src={dignostic} alt="" />
                            </div>

                        </div>
                    </div>
                </div>
            </section> */}





            {/* <section className="testimonials">
                <div className="container">
                    <h1 className='fw-bold '> <strong style={{ color: '#9D3B24' }}> Personal Journeys</strong> </h1>
                    <p className='mb-4 fw-bold text-center'>How Natural Living Has Supported Real Transformation.</p>
                    <Slider {...settings}>
                        {testimonialsData.map((testimonial, index) => (
                            <div key={index} className="item">
                                <div className="shadow-effect">
                                    <img className="img-circle" src={testimonial.imgSrc} alt={testimonial.name} />
                                    <p>{testimonial.message}</p>
                                </div>
                                <div className="testimonial-name">{testimonial.name}</div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section> */}



            <Testimonial />







        </>
    )
}

export default Consultency
