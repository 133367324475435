import React from 'react'
import '../success.css'
import success from '../success.png'

function Success() {
    return (
        <>

            <section className='consultancy-section'>
                <div className="consultancy">

                    <img src={success} alt="" />

                    <h1 className='consultancy-heading'>  OUR SUCCESS STORY </h1>




                </div>
            </section>

            <section className="book-section" id='sunita'>
                <div className="container">
                    <div className='book'>
                        <div className="book-text">
                            <h2>Sunita Jain, Sagar</h2>
                            <p>"Living with an autoimmune condition like HLA-B27 felt overwhelming until I found a natural path to relief. Through naturopathy, acupressure, and Sujok therapy, I’ve gained remarkable control over my health without the burden of medications."</p>
                        </div>
                        <div className="success-vidio">
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/bRdlAxJ_JfA"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </section>



            <section className="book-section" id='monica'>
                <div className="container">
                    <div className='book'>
                        <div className="success-vidio">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/tU61PvDCyak?si=X1Y__MmQd2pEl2Ds" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <div className="book-text">
                            <h2> Monika Jain</h2>
                            <p>"Naturopathy has been a game-changer in managing my health condition. I've found relief through natural methods that truly respect my body."</p>
                        </div>

                    </div>
                </div>
            </section>



            <section className="book-section" id='pragati'>
                <div className="container">
                    <div className='book'>
                        

                        <div className="book-text">
                            <h2> Dr. Pragati Jain</h2>
                            <p>"With a few simple, nature-based lifestyle changes, I lost weight from 62 kg to 56 kg—completely side-effect-free. It’s amazing how natural choices can improve our health.""</p>
                        </div>
                        <div className="success-vidio">
                        <img src="/images/prgati_jain.png" alt="" />
                        </div>

                    </div>
                </div>
            </section>











        </>
    )
}

export default Success
