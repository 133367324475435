import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import '../navbar.css';
import logo from '../logo.png';



function Navbar() {

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [navbarStyle, setNavbarStyle] = useState({});

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  const handleScroll = () => {
    if (window.scrollY > 150) {
      setNavbarStyle({
        padding: '5px 20px',
        backgroundColor: 'white',
      });
    } else {
      setNavbarStyle({
        backgroundColor: 'transparent',
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      {/* Main Navbar */}
      <div className="main-navbar" id="navbar" style={navbarStyle}>
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>

        <div className={`nav-links ${isMobileMenuOpen ? 'open' : ''}`}>
          <button className="close-btn" onClick={toggleMobileMenu}>
            &times;
          </button>
          <ul>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? 'main-active' : '')}
                onClick={() => {
                  toggleMobileMenu();
                  window.scrollTo(0, 0);
                }}
              >
                Home
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/consultancy"
                className={({ isActive }) => (isActive ? 'main-active' : '')}
                onClick={() => {
                  toggleMobileMenu();
                  window.scrollTo(0, 0);
                }}
              >
                Consultancy
              </NavLink>
            </li>


            <li>
              <NavLink
                to="/product"
                className={({ isActive }) => (isActive ? 'main-active' : '')}
                onClick={() => {
                  toggleMobileMenu();
                  window.scrollTo(0, 0);
                }}
              >
                Product
              </NavLink>
            </li>
            <li>

              <div className={`dropdown ${isDropdownOpen ? 'open' : ''}`}>
                <span onClick={toggleDropdown}>
                  Institute <span className="arrow">&#9662;</span>
                </span>
                <div className="dropdown-content">
                  <NavLink
                    to="/workshop"
                    className={({ isActive }) => (isActive ? 'main-active' : '')}
                    onClick={() => {
                      toggleMobileMenu();
                      window.scrollTo(0, 0);
                    }}
                  >
                    <p>Workshop</p>
                  </NavLink>
                  <NavLink
                    to="/vidieo"
                    className={({ isActive }) => (isActive ? 'main-active' : '')}
                    onClick={() => {
                      toggleMobileMenu();
                      window.scrollTo(0, 0);
                    }}
                  >
                    <p>Videos</p>
                  </NavLink>
                </div>
              </div>
            </li>
            <li>
              <NavLink
                to="/workshop"
                className={({ isActive }) => (isActive ? 'main-active' : '')}
                onClick={() => {
                  toggleMobileMenu();
                  window.scrollTo(0, 0);
                }}
              >
                Workshop
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/vidieo"
                className={({ isActive }) => (isActive ? 'main-active' : '')}
                onClick={() => {
                  toggleMobileMenu();
                  window.scrollTo(0, 0);
                }}
              >
                Videos
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={({ isActive }) => (isActive ? 'main-active' : '')}
                onClick={() => {
                  toggleMobileMenu();
                  window.scrollTo(0, 0);
                }}
              >
                Contact Us
              </NavLink>
            </li>
            <li className='animate'>
              <div className="how">
                <NavLink
                  to="/schedule"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <button type="button" className="btn">Book a Free Discovery Call</button>
                </NavLink>
              </div>
            </li>
           <li>
           <div  className='hindi-btn' id="google_translate_element"></div>
           </li>
            
           
          </ul>
        </div>

        <div class="whatsapp_float">

          <a href="https://wa.me/+917892307418" target="_blank" rel="noreferrer">
            <img src="/images/whatsapp_logo.png" height="80px" width="80px" alt='' class="whatsapp_float_btn" /></a>
        </div>

        <div className="hamburger" onClick={toggleMobileMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>
    </div>



  );
}

export default Navbar;
