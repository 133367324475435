import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import '../contact.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import contact from '../contact.png'
// import loc from '../loc.png';
// import phone from '../phone.png';
// import email from '../email.png';

function Contact({showBanner=true}) {
	const [formData, setFormData] = useState({
		name: '',
		subject: '',
		email: '',
		phone: '',
		message: ''
	});
	const [status, setStatus] = useState('');
	const [recaptchaToken, setRecaptchaToken] = useState(null); 
	const [recaptchaError, setRecaptchaError] = useState(false);

	useEffect(() => {
		AOS.init({ duration: 1500, easing: 'ease-in-out' });
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	const handleRecaptchaChange = (token) => {
		if (token) {
			setRecaptchaToken(token);
			setRecaptchaError(false);
		} else {
			setRecaptchaError(true);
		}
	};

	

	const handleSubmit = async (e) => {
		e.preventDefault();
		const { email, name, subject, phone, message } = formData;

		if (!email) {
			setStatus('Email is required');
			return;
		}
		if (!recaptchaToken) {
            setStatus('Please complete the reCAPTCHA');
            return;
        }

		try {
			const response = await fetch('https://efsouls.pythonanywhere.com/smtp_api/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ email, name, subject, phone, message })
			});


			if (response.ok) {

				// Show success alert
				alert('Email sent successfully!');
			} else {
				setStatus('Failed to send message. Please try again.');
			}
		} catch (error) {
			setStatus('An error occurred. Please try again later.');
		}
	};

	return (
		<>

{showBanner && (
                <section className='contact-banner-section'>
                    <div className="contact-banner">
                        <img src={contact} alt="Contact Banner" />
                    </div>
                </section>
            )}
			<section className='contact-section' id='contact'>
				<div className="container">
					<div className="contact">
						<div className="row justify-content-center">
							{/* <div className="col-md-6">
								<h6>Come Visit Us at </h6>
								<h1 className='mb-5'><strong>Our Address</strong></h1>
								<div className="all-contact">
									<div className="contact-info">
										<div className="contact-image">
											<img src={loc} alt="Location" />
										</div>
										<div className="text-start contact-detail">
											<p>Our Address</p>
											<h5>210/211 Soham Arcade,</h5>
										</div>
									</div>
									<div className="contact-info">
										<div className="contact-image">
											<img src={phone} alt="Phone" />
										</div>
										<div className="text-start contact-detail">
											<p>Our Phone Number</p>
											<h4>+1-323-778-4054</h4>
										</div>
									</div>
									<div className="contact-info">
										<div className="contact-image">
											<img src={email} alt="Email" />
										</div>
										<div className="text-start contact-detail">
											<p>Our Email</p>
											<h5>example@efsouls.com</h5>
										</div>
									</div>
								</div>
							</div> */}

							<div className="con-text col-md-6">
								<h6>Send Us a Message </h6>
								<h1 className='mb-5'><strong>Drop Us a Line</strong></h1>
								<form className="contact-form" onSubmit={handleSubmit}>
									<div className="row">
										<div className="col-sm-6">
											<input
												type="text"
												name="name"
												className="form-control"
												placeholder="Your Name *"
												value={formData.name}
												onChange={handleInputChange}
												required
											/>
										</div>
										<div className="col-sm-6">
											<input
												type="text"
												name="subject"
												className="form-control"
												placeholder="Your Subject *"
												value={formData.subject}
												onChange={handleInputChange}
												required
											/>
										</div>
										<div className="col-sm-6">
											<input
												type="email"
												name="email"
												className="form-control"
												placeholder="Your Email *"
												value={formData.email}
												onChange={handleInputChange}
												required
											/>
										</div>
										<div className="col-sm-6">
											<input
												type="text"
												name="phone"
												className="form-control"
												placeholder="Your Phone *"
												value={formData.phone}
												onChange={handleInputChange}
												required
											/>
										</div>
										<div className="col-md-12">
											<textarea
												name="message"
												cols="40"
												rows="4"
												className="form-control"
												placeholder="Message *"
												value={formData.message}
												onChange={handleInputChange}
												required
											></textarea>
										</div>
										<div className="col-md-12 mt-4 recaptcha-container">
											<ReCAPTCHA
												sitekey="6LeyyB0qAAAAAKnJMRS-9sqG0p7IgsaqtHzlm6S4"
												onChange={handleRecaptchaChange}
											/>
											{recaptchaError && (
												<p style={{ color: 'red' }}>reCAPTCHA failed to load. Please try again.</p>
											)}
										</div>
										<div className="col-md-12 col-xl-6">
											<div className="form-button">
												<button type="submit" className="form-btn btn-primary">
													Send Message
												</button>
											</div>
										</div>
										<div className="col-md-12 col-lg-12 message-status">
											{status && <p>{status}</p>}
										</div>
									</div>

								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Contact;
