import React from 'react'
import { NavLink } from 'react-router-dom';
import icon from '../icon.png'
import talk from '../talk.png'
import food from '../food.png'
import book from '../book.png'
function Offering() {
    return (
        <div>
            <section className='offering-section '>
                <div className="container">
                    <div className="offerings">
                        <h1 className='custom' >
                            <img src={icon} alt="" style={{ height: '52px', width: '65px', marginRight: '20px' }} />
                            Our Offerings
                        </h1>
                        <h4 className='text-center text-white fw-bold offering-title'>Anuyog guides you toward a nature-inspired, healthy lifestyle through three key pillars:</h4>
                        <div className="all_offers" data-aos="zoom-in" data-aos-duration="1000">
                            <NavLink
                                to="/consultancy"
                                className={({ isActive }) => (isActive ? 'main-active' : '')}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <div className="offers">

                                    <div className="offering-icon">
                                        <img src={talk} alt="" />
                                    </div>
                                    <div className="offering-text">
                                        <h4 className='fw-bold'> Consultancy</h4>
                                        <p > Consultancy on preventive, sustainable lifestyle choices, healthy nourishment, and conscious thought</p>
                                    </div>

                                </div>
                            </NavLink>

                            <NavLink
                                to="/product"
                                className={({ isActive }) => (isActive ? 'main-active' : '')}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <div className="offers">

                                    <div className="offering-icon">
                                        <img src={food} alt="" />
                                    </div>
                                    <div className="offering-text">
                                        <h4 className='fw-bold'> Products </h4>
                                        <p >Carefully chosen products to support your journey toward
                                            sustainable well being
                                        </p>
                                    </div>
                                </div>
                            </NavLink>


                            <NavLink
                                to="/vidieo"
                                className={({ isActive }) => (isActive ? 'main-active' : '')}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <div className="offers">

                                    <div className="offering-icon">
                                        <img src={book} alt="" />
                                    </div>
                                    <div className="offering-text">
                                        <h4 className='fw-bold'> Institute</h4>
                                        <p > Education and awareness that empower you to make conscious decisions for healthy living.</p>
                                    </div>

                                </div>
                            </NavLink>



                        </div>
                        <div className="how">
                            <NavLink
                                to="/schedule"
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <button type="button" className="btn">Book a Free Discovery Call</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Offering
