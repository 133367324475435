
import './App.css';
import './responsive.css'
import {  HashRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './compoments/Navbar';
import Footer from './compoments/Footer';
import Home from './compoments/Home';
import Workshop from './compoments/Workshop';
import Consultancy from './compoments/Consultancy';
import Product from './compoments/Product';
import WorkshopDetails from './compoments/workshopDetails';
import Education from './compoments/Education';
import Vidieo from './compoments/Video';
import Contact from './compoments/Contact';
import Expert from './compoments/Expert'
import Schedule from './compoments/Schedule';
import ProductDetail from './compoments/ProductDetail';
import Success from './compoments/Success';
import Disclaimer from './compoments/Disclaimer';




function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/consultancy" element={<Consultancy />} />
          <Route path="/product" element={<Product />} />
          <Route path="/vidieo" element={<Vidieo />} />
          <Route path="/education" element={<Education />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/workshopdetails" element={<WorkshopDetails />} />
          <Route path="/workshop" element={<Workshop />} />
          <Route path="/expert/:slug" element={<Expert />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/productdetail/:productId" element={<ProductDetail />} />
          <Route path="/success" element={<Success />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
         
          
                
          
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;