import React, { useEffect } from 'react'
import education from '../education.jpg'
import '../education.css'
import '../consultancy.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../Workshop.css';
import course from '../course.jpg'
function Education() {



  useEffect(() => {
    AOS.init({ duration: 1500, easing: 'ease-in-out' });
  }, []);
  return (
    <>


      <section className='education-section'>
        <div className="education-image">
          <img src={education} alt="" />
        </div>
      </section>

      <section className='course-section section-gapping'>
        <div className="container">
          <div className="course">
            <h1 className='mb-3'><strong>Anuyog Institute</strong></h1>
            <h4 className='fw-bold mb-4'>COURSES</h4>

            <section className="upcoming-section section-gapping">
              <div className="container">
                <div className="upcoming">

                  <div className="upcoming-detail" data-aos="fade-up" >
                    <div className="upcoming-image">
                      <img src={course} alt="" />

                    </div>

                    <div className="upcoming-info">
                      <div className="upcoming-text">
                        <h3 className='fw-bold'>DIPLOMA IN NATUROPATHY AND YOGA (NDDY)  </h3>
                        <p className='text-dark'>Master the art of Naturopathy to take charge of your health and be your own doctor.
                          Become a Naturopathy Doctor </p>
                        
                        <p className='text-dark'>Shri Vardhman Nyas, Amayan, Bhind has made this possible for us. They are offering a DIPLOMA IN 
                        NATUROPATHY AND YOGA (NDDY)  </p>
                        <a href="path/to/your-file.pdf" className="download-button mb-2" download>
                          Download Broucher
                        </a>
                      </div>
                      <div className="course-details">
                        <h5><strong>Authorization:</strong>Gandhi National Academy of Naturopathy, New Delhi</h5>
                        <h5><strong>Duration:</strong>Three years course followed by compulsory practical training of six months. </h5>
                      </div>
                      <div className="course-button">
                      <button type='button' className='btn ms-3'><a href="/detail">View Details</a></button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

    </>
  )
}

export default Education
