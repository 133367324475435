import React, { useEffect } from 'react'
import '../consultancy.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../product.css'
import khand from '../Khand.jpg'
import jaggrey from '../Jaggery.png'
import amrit from '../Amrit_Pey.jpg'
import product from '../product.png'
import { NavLink } from 'react-router-dom';

function Product() {

  const products = [
    { id: 1, name: "Amrit Pey", description: "Amrit Pey is a unique blend of 14 spices designed to help you overcome coffee and tea addiction.", img: amrit },
    { id: 2, name: "Khand", description: "Khand is a type of unrefined raw sugar commonly used in Indian cuisine and traditional Ayurvedic preparations.", img: khand },
    { id: 3, name: "Jaggery", description: "Jaggery is a traditional, unrefined sweetener commonly used in Indian cuisine and Ayurvedic preparations.", img: jaggrey },
  ];


  useEffect(() => {
    AOS.init({ duration: 1500, easing: 'ease-in-out' });
  }, []);


  return (
    <>
      <section className='product-banner-section'>
        <div className="product-banner">
          <img src={product} alt="" />
          <h1 className='consultancy-heading  '>"Food is medicine—when you eat right, it heals"</h1>

        </div>
      </section>



      <section className='choose-section'>
        <div className="container">
          <div className="choose">
            <div className="work">

              
          

              <div className="work-text mb-4" >
                <h3
                  className='fonts text-center text-dark mb-4 fw-bold offering-title'
                  style={{ maxWidth: '930px', margin: '0 auto' }}
                >
                  Our goal is to provide you with
                  <strong style={{ color: '#9D3B24' }}>carefully chosen products </strong> that empower your 
                  <strong style={{ color: '#9D3B24' }}> natural living journey 
                  </strong> to a <strong style={{ color: '#9D3B24' }}> healthier, sustainable lifestyle
                  </strong>
                  
                </h3>
              </div>

            </div>
            <p className='text-center mb-4'><strong style={{ color: '#000', maxWidth: '630px', display: 'block', margin: '0 auto' }}> We place high value on consuming adulteration-free products for a healthier lifestyle and strive to uphold the following commitments. However, as not everything is within our control, we kindly ask for your understanding in advance for any service inconveniences.</strong>
            </p>
            <div className="all-choose row">
              <div className="choose-details col-md-3">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <h1>BEST QUALITY</h1>
                    </div>
                    <div className="flip-card-back">
                      <p className="title mb-3">No compromise in quality</p>
                      <p>We source and deliver only the highest-quality products, crafted to  meet rigorous standards of excellence.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose-details col-md-3">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <h1>100% NATURAL</h1>
                    </div>
                    <div className="flip-card-back">
                      <p className="title mb-3"> Nature in every Product
                      </p>
                      <p>Our products are free from artificial additives, ensuring you receive only wholesome, natural ingredients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose-details col-md-3">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <h1>PURE AND CLEAN</h1>
                    </div>
                    <div className="flip-card-back">
                      <p className="title mb-3">Purity comes first</p>
                      <p>We ensure that our product retain their full nutritional value and purity.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="choose-details col-md-3">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <h1>TRUSTIFIED</h1>
                    </div>
                    <div className="flip-card-back">
                      <p className="title mb-3">Your Trust Our Responsiblity</p>
                      <p>We ensure that you can trust every product we offer.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product-section">
        <div className="container">
          <div className="product">
            <h1 className='mb-5 offering-title'>OUR PRODUCTS</h1>
            <div className="row">
              {products.map((product) => (
                <div className="col-md-4" key={product.id}>
                  <div className="card" style={{ width: "18rem" }}>
                    <NavLink to={`/productdetail/${product.id}`} onClick={() => window.scrollTo(0, 0)}>
                      <img src={product.img} className="card-img-top" alt="" />
                    </NavLink>
                    <div className="card-body">
                      <NavLink to={`/productdetail/${product.id}`} onClick={() => window.scrollTo(0, 0)}>
                        <h5 className="card-title">{product.name}</h5>
                      </NavLink>
                      <p className="card-text">{product.description}</p>
                      <NavLink to={`/productdetail/${product.id}`} onClick={() => window.scrollTo(0, 0)} className="btn btn-primary">
                        READ MORE
                      </NavLink>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>



      


























      {/* <section className='jaggrey-section ' id='amrit-pey'>
        <div className="container">
          <div className="jaggrey">
            <div className="row align-items-center">

              <div className="intro-text text-start col-md-6" data-aos="fade-right">
                <h1><strong> Amrit Pey  </strong></h1>
                <p>Amrit Pey is a unique blend of 14 spices designed to help you overcome coffee and tea addiction,
                  strengthen your immune system, and provide relief from cold and cough. </p>

                <h6 className='fw-bold'><strong>Key Features</strong></h6>
                <p>Expertly crafted by Dr. Suresh Jain, a Nature cure specialist from Bhind (M.P). </p>
                <p>Each ingredient is meticulously selected, cleaned, washed, dried, and ground to ensure the highest
                  quality and purity.</p>
                <h4 className='fw-bold'><strong>How To Purchase Amrit Pey?</strong></h4>
                <p>To purchase, please contact Dr. Suresh Jain for. The product will be sent to you upon
                  request.</p>
              </div>
              <div className="jaggrey-image col-md-6">
                <img src={amrit} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='khand-section'id='khand'>
        <div className="container">
          <div className="khand">
            <div className="row align-items-center">
              <div className="khand-image col-md-6" data-aos="fade-right">
                <img src={khand} alt="" />
              </div>
              <div className="intro-text text-start col-md-6" data-aos="fade-left">
                <h1><strong> KHAND  </strong></h1>
                <p>Khand is a type of unrefined raw sugar commonly used in Indian cuisine and traditional Ayurvedic
                  preparations. It is known for being less processed compared to white sugar and retains more of the
                  natural minerals and nutrients present in sugarcane juice. </p>

                <h6 className='fw-bold'><strong>Key Features</strong></h6>
                <p>Prepared meticulously by Brahamchari Shri Nanhe Bhaiya, from Sagar (M.P).</p>
                <p>This product is crafted with the singular aim of providing pure and wholesome sugar for your
                  consumption. </p>
                <h4 className='fw-bold'><strong>How To Purchase Khand?</strong></h4>
                <p>To purchase, please contact Brahamchari Shri Nanhe Bhaiya at <strong>To purchase, please contact Brahamchari Shri Nanhe Bhaiya for. The product will be sent
                  to you upon request.</strong>The product will be sent
                  to you upon request.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='jaggrey-section'id='jaggrey'>
        <div className="container">
          <div className="jaggrey">
            <div className="row align-items-center">

              <div className="intro-text text-start col-md-6" data-aos="fade-right">
                <h1><strong> JAGGERY  </strong></h1>
                <p>Jaggery is a traditional, unrefined sweetener commonly used in Indian cuisine and Ayurvedic
                  preparations. Known for its rich flavor and nutritional benefits, jaggery retains more of the natural
                  minerals and nutrients found in sugarcane juice compared to refined sugar.</p>

                <h6 className='fw-bold'><strong>Key Features</strong></h6>
                <p>Prepared meticulously by Brahamchari Shri Nanhe Bhaiya, from Sagar (M.P).</p>
                <p>This product is crafted with the singular aim of providing pure and wholesome sugar for your
                  consumption. </p>
                <h4 className='fw-bold'><strong>How To Purchase jaggery?</strong></h4>
                <p>To purchase, please contact Brahamchari Shri Nanhe Bhaiya at for The product will be sent
                  to you upon request.</p>
              </div>
              <div className="jaggrey-image col-md-6">
                <img src={jaggrey} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section> */}




      {/* <section className="ingredients-section section-gapping">
        <div className="container">

          <div className="ingredients">
            <h2 className='mb-5'><strong>INGREDIENTS</strong></h2>
            <table data-aos="zoom-in-up">
              <tr>
                <th>Ingredients</th>
                <th>Quantity</th>
                <th>Benifits</th>
              </tr>
              <tr>
                <td>Dried Ginger (Sonth)</td>
                <td> 50 grams </td>
                <td>Relieves sore throat and reduces inflammation.</td>
              </tr>
              <tr>
                <td> Black Pepper</td>
                <td> 50 grams </td>
                <td> Breaks down mucus and boosts the immune system. </td>

              </tr>
              <tr>
                <td> Long Pepper (Choti Peepal) </td>
                <td>25 grams </td>
                <td>Treats respiratory disorders and improves digestion.</td>

              </tr>
              <tr>
                <td>Cinnamon </td>
                <td>25 grams </td>
                <td>Soothes a sore throat and boosts the immune system.</td>
              </tr>
              <tr>
                <td> Green Cardamom</td>
                <td>25 grams </td>
                <td>Soothes the respiratory tract and aids digestion.</td>
              </tr>
              <tr>
                <td> Fennel Seeds </td>
                <td> 50 grams </td>
                <td>Relieves cough and aids digestion.</td>

              </tr>
              <tr>
                <td>Carom Seeds (Ajwain) </td>
                <td>25 grams</td>
                <td>Acts as an expectorant and provides antimicrobial benefits.</td>
              </tr>
              <tr>
                <td> Cloves </td>
                <td>25 grams</td>
                <td>Relieves sore throat and boosts the immune system. </td>
              </tr>
              <tr>
                <td>Licorice (Mulethi) </td>
                <td>100 grams</td>
                <td> Soothes the throat and reduces inflammation.</td>
              </tr>
              <tr>
                <td>Ashwagandha </td>
                <td>25 grams</td>
                <td>Reduces stress and enhances respiratory health.</td>
              </tr>
              <tr>
                <td>Arjuna Bark  </td>
                <td>50 grams</td>
                <td>Supports cardiovascular and respiratory health. </td>
              </tr>
              <tr>
                <td>Pomegranate Peel  </td>
                <td>50 grams</td>
                <td>Rich in antioxidants and supports immune function.</td>
              </tr>
              <tr>
                <td>Fenugreek Seeds  </td>
                <td>50 grams</td>
                <td>Relieves sore throat and boosts immune function.</td>
              </tr>
              <tr>
                <td> Holy Basil Leaves (Tulsi)  </td>
                <td>25 grams</td>
                <td> Provides relief from respiratory disorders and boosts immunity. </td>
              </tr>
            </table>
            

          </div>
        </div>
      </section> */}

      {/* <section className='purchase-section section-gapping'>
        <div className="container">
          <div className="purchase">
            <div className="row ">
              <h2 className=' mb-3'><strong>How To Purchase Amrit Pey?</strong></h2>
              <h6 className=' mb-5 fw-bold'>Ready to experience the benefits of <strong>Amrit Pey?</strong> It's easy! Follow these simple steps to make your purchase:</h6>
              <div className="col-md-6">

                <div className="video-container" data-aos="fade-right">
                  <iframe
                    src="https://www.youtube.com/embed/Wgno3CfOwQc?si=FVovMKWfysEly2Lj"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <div className=" text-start col-md-6" data-aos="fade-left">

                <h5> <strong>1.Contact Dr. Suresh Jain</strong></h5>
                <p>Reach out directly to Dr. Suresh Jain, the creator of Amrit Pey, by calling <strong>9826646644.</strong> He will be happy to assist you with your order and answer any questions you may have.</p>
                <h5><strong>2.Place Your Order</strong></h5>
                <p>Let Dr. Jain know your interest in Amrit Pey, and he will guide you through the purchasing process.</p>
                <h5><strong>3.Receive Your Product</strong></h5>
                <p>Once your order is confirmed, the product will be sent directly to your doorstep upon request, ensuring a hassle-free experience.</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}





    </>
  )
}

export default Product
