import React, { useEffect } from 'react';
import '../schedule.css';
import 'react-calendar/dist/Calendar.css';

function Schedule() {


    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;

        script.onload = () => {
            window.Calendly.initBadgeWidget({
                url: 'https://calendly.com/dhruvraja36/meeting?hide_event_type_details=1&hide_gdpr_banner=1&embed_type=inline', // Add embed_type
                text: 'Schedule time with me',
                color: '#0069ff',
                textColor: '#ffffff',
                branding: undefined
            });
        };

        document.body.appendChild(script);


        return () => {
            const badgeWidget = document.querySelector('.calendly-badge');
            if (badgeWidget) {
                badgeWidget.remove();
            }
        };
    }, []);



    return (
        <div>

            <section className="book-section">
                <div className="container">
                    <div className='book'>
                        <div className="book-text">
                            <h2>book a free call with our experts</h2>
                            <p>we are here to address your doubts and show how Anuyog can help</p>
                            <p> <strong style={{ color: '#9D3B24' }}>Disclaimer: We will not be providing any medical advice </strong> </p>
                        </div>
                        <div
                            className="calendly-inline-widget"
                            data-url="https://calendly.com/dhruvraja36/meeting?hide_event_type_details=1&hide_gdpr_banner=1&embed_type=inline" // Add embed_type parameter here
                            style={{ minWidth: '320px', height: '630px' }}
                        ></div>
                    </div>
                </div>
            </section>

            <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
        </div>
    );
}

export default Schedule;
