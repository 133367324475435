import React, { useEffect } from 'react'
import '../product.css'
import '../productdetail.css'

import 'aos/dist/aos.css';
import pro from '../product.png'
import amrit from '../Amrit_Pey.jpg'
import khand from '../Khand.jpg'
import jaggrey from '../Jaggery.png'
import { useParams } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';


function ProductDetail() {


  const { productId } = useParams();


  const productData = {
    1: {
      name: "Amrit Pey",
      description: "Amrit Pey is a unique blend of 14 spices designed to help you overcome coffee and tea addiction, strengthen your immune system, and provide relief from cold and cough.",
      features: [
        "Expertly crafted by Dr. Suresh Jain, a Nature cure specialist from Bhind (M.P).",
        "Each ingredient is meticulously selected, cleaned, washed, dried, and ground to ensure the highest quality and purity."
      ],
      purchaseInfo: "To purchase, please contact us at theanuyoginstitute@gmail.com. The product will be sent to you upon request.",
      ingredients: [
        "Dried Ginger (Sonth).................................................... 50 grams",
        "Black Pepper............................................................ 50 grams",
        "Long Pepper (Choti Peepal)...............................................25 grams",
        "Cinnamon ............................................................... 25 grams",
        "Green Cardamom.......................................................... 25 grams",
        "Fennel Seeds............................................................ 50 grams",
        "Carom Seeds (Ajwain).................................................... 25 grams",
        "Cloves.................................................................. 25 grams",
        "Licorice (Mulethi) ..................................................... 100 grams",
        "Ashwagandha............................................................. 25 grams",
        "Arjuna Bark............................................................. 50 grams",
        "Pomegranate Peel........................................................ 50 grams",
        "Fenugreek Seeds......................................................... 50 grams",
        "Holy Basil Leaves (Tulsi) .............................................. 25 grams",
      ],
      img: amrit
    },
    2: {
      name: "Khand",
      description: "Khand is a type of unrefined raw sugar commonly used in Indian cuisine and traditional Ayurvedic preparations. It is known for being less processed compared to white sugar and retains more of the natural minerals and nutrients present in sugarcane juice.",
      features: [
        "Prepared meticulously by Brahamchari Shri Nanhe Bhaiya, from Sagar (M.P).",
        "This product is crafted with the singular aim of providing pure and wholesome sugar for your consumption."
      ],
      purchaseInfo: "To purchase, please contact us at theanuyoginstitute@gmail.com. The product will be sent to you upon request.",
      ingredients: [
        "Sugarcane extract - 100 grams",
        "Natural minerals - trace amounts"
      ],
      img: khand
    },
    3: {
      name: "Jaggery",
      description: "Jaggery is a traditional, unrefined sweetener commonly used in Indian cuisine and Ayurvedic preparations. Known for its rich flavor and nutritional benefits, jaggery retains more of the natural minerals and nutrients found in sugarcane juice compared to refined sugar.",
      features: [
        "Prepared meticulously by Brahamchari Shri Nanhe Bhaiya from Sagar (M.P).",
        "our jaggery is crafted with the singular aim of providing pure and wholesome sweetness for your consumption."
      ],
      purchaseInfo: "To purchase, please contact us at theanuyoginstitute@gmail.com. The product will be sent to you upon request.",
      ingredients: [
        "Sugarcane juice - 100 grams",
        "Natural molasses - trace amounts"
      ],
      img: jaggrey
    }
  };

  const product = productData[productId];

  useEffect(() => {
    // Scrolls to top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  if (!product) {
    return <div>Product not found</div>;
  }




  return (
    <>

      <section className='product-banner-section'>
        <div className="product-banner">
          <img src={pro} alt="" />
        </div>
      </section>
      <section className="product_details-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="text-start product-title">{product.name}</h1>
              <p>{product.description}</p>

              <h4 className="text-start mt-4"><strong>Key Features</strong></h4>

              {product.features.map((feature, index) => (
                <p key={index}>{feature}</p>
              ))}
              <h4 className="text-start mt-4"><strong>How to Purchase?</strong></h4>
              <p>To place an order, simply contact us via one of the following methods:</p>
              <p><strong>Chat with Us on WhatsApp</strong> — Start a conversation and place your order directly on WhatsApp.</p>
              <p><strong>Send Us an Email</strong> — Email us your order request, and we’ll take care of the rest!</p>
              <a
                href={`https://wa.me/+917892307418?text=I%20want%20to%20buy%20${encodeURIComponent(product.name)}`}
                target="_blank"
                rel="noreferrer"
              >
                <button type="button" className="btn mt-3 me-md-5">
                  Order via WhatsApp
                </button>
              </a>             
              <Link to="/contact#contact" smooth>
                <button type="button" className="btn mt-3 me-md-5">
                  Order via Email
                </button>
              </Link>
            </div>
            <div className="details_image col-md-6">
              <div className="image-wrapper">
                <img src={product.img} alt={product.name} />
              </div>
              <h2 className="fw-bold mt-4">WHAT'S INSIDE</h2>
              <h4><strong>Ingredients</strong></h4>
              <ul className="fw-bold text-start">
                {product.ingredients.map((ingredient, index) => (
                  <li key={index}>{ingredient}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ProductDetail
