import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import calender from '../calender.png';
import '../Workshop.css';
import '../responsive.css';
import workshop from '../Workshops.png'
import workshop01 from '../workshop01.png'



function Workshop() {
    const [attendanceRecords, setAttendanceRecords] = useState([]);
    // const navigate = useNavigate();

    useEffect(() => {
        const fetchAttendance = async () => {
            try {
                const response = await axios.get('https://efsouls.pythonanywhere.com/workshops/');
                setAttendanceRecords(response.data);
            } catch (error) {
                console.error("Error fetching attendance records:", error);
            }
        };

        fetchAttendance();
        AOS.init({
            duration: 1500,
            easing: 'ease-in-out',
        });
    }, []);

    // const handleViewClick = (workshop) => {
    //     navigate('/workshopdetails', { state: { workshop } });
    // };

    return (
        <div>
            <section className="workshop-section">
                <div className="workshop-image">
                    <img src={workshop} alt="" />
                </div>
            </section>

            <section className="upcoming-section section-gapping">
                <div className="container">
                    <div className="upcoming">

                        <div className="upcoming-detail" data-aos="fade-up">
                            <div className="upcoming-image">
                                <img src={workshop01} alt="" />
                                <div className="close">

                                    <span>upcoming soon</span>
                                </div>
                            </div>

                            <div className="upcoming-info">
                                <div className="upcoming-text">
                                    <h3>Natural Well-Being</h3><br />
                                    <p><strong>Topics covered: <br /></strong>
                                        The Foundation of Natural Living, <br />Cultivating Mind-Body Harmony,<br />Empowering Sustainable Health
                                        Accupressure
                                    </p>

                                </div>
                                {/* <div className="timing">
                                    <div className="timing-info">
                                        <div className="timing-image">
                                            <img src={calender} alt="Calendar" />
                                            <h5>2024-09-27</h5>
                                        </div>
                                    </div>
                                    <div className="timing-info">
                                        <div className="timing-image">
                                            <img src={calender} alt="Calendar" />
                                            <h5>13:39:00 - 01:39:00</h5>
                                        </div>
                                    </div>
                                    <div className="timing-info">
                                        <div className="timing-text">
                                            <h5>$78787.00</h5>
                                        </div>
                                    </div>
                                    <div className="timing-info">
                                        <div className="timing-text">
                                            <h5>ENGLISH</h5>
                                        </div>
                                    </div>
                                </div> */}

                                <a href="/">
                                    <button type="button" className="btn" style={{ marginLeft: 0, textAlign: 'left' }}>
                                        Coming Soon
                                    </button>
                                </a>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="upcoming-section section-gapping">
                <div className="container">
                    <div className="upcoming">
                        {attendanceRecords.map((record) => (
                            <div className="upcoming-detail" data-aos="fade-up" key={record.id}>
                                <div className="upcoming-image">
                                    <img src={`https://efsouls.pythonanywhere.com/${record.image}`} alt="" />
                                    <div className="close">
                                        <span>CLOSE IN</span><br />
                                        <span>{record.end_time ? 'Closed' : 'Ongoing'}</span>
                                    </div>
                                </div>

                                <div className="upcoming-info">
                                    <div className="upcoming-text">
                                        <h3>{record.title}</h3>
                                        <p>{record.description}</p>
                                    </div>
                                    <div className="timing">
                                        <div className="timing-info">
                                            <div className="timing-image">
                                                <img src={calender} alt="Calendar" />
                                                <h5>{record.date}</h5>
                                            </div>
                                        </div>
                                        <div className="timing-info">
                                            <div className="timing-image">
                                                <img src={calender} alt="Calendar" />
                                                <h5>{record.start_time} - {record.end_time}</h5>
                                            </div>
                                        </div>
                                        <div className="timing-info">
                                            <div className="timing-text">
                                                <h5>${record.fees}</h5>
                                            </div>
                                        </div>
                                        <div className="timing-info">
                                            <div className="timing-text">
                                                <h5>{record.language}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <button type='button' className='btn' onClick={() => handleViewClick(record)}>
                                        View
                                    </button> */}
                                    <a href="/contact"><button type='button' className='btn ms-3'>Register</button></a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Workshop;
