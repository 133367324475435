import React from 'react'
import "../disclaimer.css"

function Disclaimer() {
  return (
    <>
        <section className='disclaimer-banner-section'>
        <div className="disclaimer-banner">
           <img src="/images/disclaimer-banner.png" alt="" />
        </div>
      </section>

      <section className='disclaimer-section'id='disclaimer'>
        <div className="container">
            <h1>Disclaimer and Disclosure</h1>

            <p>The information, guidance, and materials provided by Anuyog are intended solely for educational and informational purposes. Anuyog does not provide medical advice, and its content should not be used as a substitute for professional medical consultation or treatment. Users are encouraged to consult qualified healthcare professionals for specific health-related concerns.</p>

            <p>By using Anuyog’s services, you acknowledge that any actions taken based on the information provided are at your own risk. Anuyog shall not be held liable for any outcomes resulting from the use or interpretation of its materials or services.
            </p>

            <h1 className='mt-5'>Data Privacy</h1>
            <p>Anuyog is committed to protecting your privacy. Any personal information collected during your interaction with our platform, such as during consultations or assessments, will be securely stored and handled in accordance with applicable data protection laws (such as GDPR or any other relevant jurisdictional regulation). Your data will not be shared with third parties without explicit consent, except where required by law.
            </p>
            <p>However, no method of data transmission or storage is completely secure, and Anuyog cannot guarantee absolute security. By using our services, you acknowledge and agree that Anuyog will not be responsible for any unauthorized access, alteration, or breach of your personal data.
            </p>
            <p className='mb-4
            '>Anuyog reserves the right to modify its policies, and users are encouraged to review the latest version of the disclaimer and privacy policy to stay informed.
            </p>
        </div>
      </section>
    </>
  )
}

export default Disclaimer
