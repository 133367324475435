// import React, { useEffect, useState } from 'react';
// import '../vidio.css';
// import logo from '../logo.png';
// import vid from '../vid.png';
// import axios from 'axios';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import fb from '../fb.png'
// import ig from '../ig.png'
// import youtube from '../you.png'


// function Vidieo() {

//     const [videos, setVideos] = useState([]);
//     const [shorts, setShorts] = useState([]);
//     const [playlists, setPlaylists] = useState([]);
//     const [activeTab, setActiveTab] = useState('home');

//     useEffect(() => {
//         const API_KEY = ' AIzaSyDbCHy7ggFzp8dk7E6gI3MMID12rXyfe0Q';
//         const CHANNEL_ID = 'UCziUYu9aahvyE251nJHwhMg';






//         const fetchVideos = async () => {
//             try {

//                 const { data: playlistData } = await axios.get(`https://www.googleapis.com/youtube/v3/channels?part=contentDetails&id=${CHANNEL_ID}&key=${API_KEY}`);
//                 const uploadsPlaylistId = playlistData.items[0].contentDetails.relatedPlaylists.uploads;


//                 const { data: videosData } = await axios.get(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${uploadsPlaylistId}&key=${API_KEY}&maxResults=50`);

//                 setVideos(videosData.items);
//             } catch (error) {
//                 console.error("Error fetching videos:", error);
//             }
//         };

//         const fetchShorts = async () => {
//             let allShorts = [];
//             let nextPageToken = '';

//             try {
//                 do {
//                     const { data: shortsData } = await axios.get(
//                         `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${CHANNEL_ID}&type=video&videoDuration=short&key=${API_KEY}&maxResults=50&pageToken=${nextPageToken}`
//                     );

//                     allShorts = [...allShorts, ...shortsData.items];
//                     nextPageToken = shortsData.nextPageToken;
//                 } while (nextPageToken);

//                 setShorts(allShorts);
//             } catch (error) {
//                 console.error("Error fetching shorts:", error);
//             }
//         };

//         const fetchPlaylists = async () => {
//             let allPlaylists = [];
//             let nextPageToken = '';

//             try {
//                 do {
//                     const { data: playlistsData } = await axios.get(
//                         `https://www.googleapis.com/youtube/v3/playlists?part=snippet&channelId=${CHANNEL_ID}&key=${API_KEY}&maxResults=50&pageToken=${nextPageToken}`
//                     );

//                     allPlaylists = [...allPlaylists, ...playlistsData.items];
//                     nextPageToken = playlistsData.nextPageToken;
//                 } while (nextPageToken);

//                 setPlaylists(allPlaylists);
//             } catch (error) {
//                 console.error("Error fetching playlists:", error);
//             }
//         };


//         fetchVideos();
//         fetchShorts();
//         fetchPlaylists();
//         // fetchCommunityPosts();
//     }, []);

//     useEffect(() => {
//         AOS.init({ duration: 1500, easing: 'ease-in-out' });
//     }, []);


//     const handleTabClick = (tab) => {
//         setActiveTab(tab);
//     };


//     return (
//         <>

//             <section className='vidio-banner-section'>
//                 <div className="vidio-banner">
//                     <img src={vid} alt="" />
//                     <h1
//   className="consultancy-heading"
//   style={{
//     width: "100%",
//     lineHeight: "1.2",
//     textAlign: "center",
//   }}
// >
//   Discover and develop a true understanding of a balanced,
//   <br />
//   natural lifestyle through  expert-reviewed content.
// </h1>
//                 </div>
//             </section>
//             <section className='head-section section-gapping'>
//                 <div className="container">
//                     <h1 className='mb-5'><strong>The Anuyog Institute</strong></h1>
//                     <div className="head">
//                         <div className="head-detail">
//                             <div className="head-logo">
//                                 <img src={logo} alt="" />
//                             </div>
//                             <div className="head-text">
//                                 <h3>Subscribe on your favorite platform
//                                 </h3>
//                                 <ul className='social'>
//                                     <a href="https://www.youtube.com/@theanuyoginstitute"><li><img src={youtube} alt="" /></li></a>
//                                     <a href="https://www.facebook.com/theanuyoginstitute/"><li><img src={fb} alt="" /></li></a>
//                                     <a href="https://www.instagram.com/theanuyoginstitute/"> <li><img src={ig} alt="" /></li></a>

//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             {/* <section className="drope-section section-gapping custom-scrollbar-x">
//                 <div className="drope-text">
//                     <h4>Most Watched Vidios from Anuyog Institute</h4>
//                 </div>

//                 <div className="container">
//                     <div className="scroll">
//                         <div className="pro">
//                             <div className="drope-image">
//                                 <iframe width="500" height="315" src="https://www.youtube.com/embed/0vnVWHlIZ1g?si=T4-EksxhLSNU2Cy6" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
//                             </div>
//                             <div className="drope-text">
//                                 <h3>Day 7 | हर दिन ध्यान देने योग्य तीन प्रमुख बातें और योग आसन के राजा | Three Daily Focus Areas</h3>
//                             </div>

//                         </div>



//                         <div className="pro">
//                             <div className="drope-image">
//                                 <iframe width="500" height="315" src="https://www.youtube.com/embed/yZ2IAtOtKQc?si=wqzPNE-8yus7MWuo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
//                             </div>
//                             <div className="drope-text">
//                                 <h3>Day 3| बैठकर पैर लम्बे करने वाले योग आसन | Sitting leg lengthening Yogasan | 10 Mins</h3>
//                             </div>

//                         </div>


//                         <div className="pro">
//                             <div className="drope-image">
//                                 <iframe width="500" height="315" src="https://www.youtube.com/embed/EGEefSsFkLw?si=5jpgbqqKAReyDxKR" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
//                             </div>
//                             <div className="drope-text">
//                                 <h3>प्राणायाम | Pranayam | Mind Relaxation, Obesity, Gastric Trouble, Kidney Disorder, Thyroid & Snoring</h3>
//                             </div>

//                         </div>


//                         <div className="pro">
//                             <div className="drope-image">
//                                 <iframe width="500" height="315" src="https://www.youtube.com/embed/ILlFw1QL-uc?si=OpGR2PHQxj5QWMPx" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
//                             </div>
//                             <div className="drope-text">
//                                 <h3>पीठ के योगासन | Back Yoga Asanas | Strong Legs, Pelvic & Back, Reduce Belly Fat & Increase Immunity</h3>
//                             </div>

//                         </div>

//                         <div className="pro">
//                             <div className="drope-image">
//                                 <iframe width="500" height="315" src="https://www.youtube.com/embed/A2N30kUnp_M?si=sTcLEgsLE9v6u7u0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
//                             </div>
//                             <div className="drope-text">
//                                 <h3>प्राणायाम और श्वास व्यायाम (भाग 2) | Pranayama & Breathing Exercise (Part2) </h3>
//                             </div>

//                         </div>

//                     </div>
//                 </div>
//             </section>


//             <section className="videos-section section-gapping">
//                 <div className="container">
//                     <h1 className='mb-5'>Videos from The Anuyog Institute</h1>
//                     <div className="videos-grid">
//                         {videos.map(video => (
//                             <div className="video-item" key={video.id}>
//                                 <div className="drope-image">
//                                     <iframe
//                                         width="100%"
//                                         height="315"
//                                         src={`https://www.youtube.com/embed/${video.snippet.resourceId.videoId}`}
//                                         title="YouTube video player"
//                                         frameBorder="0"
//                                         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//                                         referrerPolicy="strict-origin-when-cross-origin"
//                                         allowFullScreen
//                                     ></iframe>
//                                 </div>
//                                 <div className="drope-text vidio-custom">
//                                     <h3>{video.snippet.title}</h3>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             </section> */}




//             <section className="tabs-section section-gapping">
//                 <div className="container">
//                     <div className="tabs">
//                     <button
//                 className={activeTab === 'home' ? 'active' : ''}
//                 onClick={() => handleTabClick('home')}>
//                 Home
//             </button>
//                         <button
//                             className={activeTab === 'videos' ? 'active' : ''}
//                             onClick={() => handleTabClick('videos')}>
//                             Videos
//                         </button>
//                         <button
//                             className={activeTab === 'shorts' ? 'active' : ''}
//                             onClick={() => handleTabClick('shorts')}>
//                             Shorts
//                         </button>
//                         <button
//                             className={activeTab === 'playlists' ? 'active' : ''}
//                             onClick={() => handleTabClick('playlists')}>
//                             Playlists
//                         </button>
//                         {/* <button
//                             className={activeTab === 'communityPosts' ? 'active' : ''}
//                             onClick={() => handleTabClick('communityPosts')}>
//                             Community Posts
//                         </button> */}
//                     </div>
//                 </div>
//             </section>

//             {/* Content Section */}
//             <section className={activeTab === 'home' ? 'home-section section-gapping' :
//     activeTab === 'videos' ? 'videos-section section-gapping' :
//     activeTab === 'shorts' ? 'shorts-section section-gapping' :
//     activeTab === 'playlists' ? 'playlists-section section-gapping' : 'community-posts-section section-gapping'}>
//     <div className="container">
//         {activeTab === 'home' ? (
//             <>
//                 <h1 className='mb-5 offering-title'>All Videos from The Anuyog Institute</h1>
//                 <div className="videos-grid">
//                     {/* Combine regular videos and shorts for the Home tab */}
//                     {[...videos, ...shorts].map(item => (
//                         <div className="video-item" key={item.id.videoId || item.id}>
//                             <div className="drope-image">
//                                 <iframe
//                                     width="100%"
//                                     height="315"
//                                     src={`https://www.youtube.com/embed/${item.id.videoId || item.id}`}
//                                     title="YouTube video player"
//                                     frameBorder="0"
//                                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//                                     referrerPolicy="strict-origin-when-cross-origin"
//                                     allowFullScreen
//                                 ></iframe>
//                             </div>
//                             <div className="drope-text vidio-custom">
//                                 <h3>{item.snippet.title}</h3>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </>
//         ) : activeTab === 'videos' ? (
//             <>
//                 <h1 className='mb-5'>Videos from The Anuyog Institute</h1>
//                 <div className="videos-grid">
//                     {videos.map(video => (
//                         <div className="video-item" key={video.id}>
//                             <div className="drope-image">
//                                 <iframe
//                                     width="100%"
//                                     height="315"
//                                     src={`https://www.youtube.com/embed/${video.snippet.resourceId.videoId}`}
//                                     title="YouTube video player"
//                                     frameBorder="0"
//                                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//                                     referrerPolicy="strict-origin-when-cross-origin"
//                                     allowFullScreen
//                                 ></iframe>
//                             </div>
//                             <div className="drope-text vidio-custom">
//                                 <h3>{video.snippet.title}</h3>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </>
//         ) : activeTab === 'shorts' ? (
//             <>
//                 <h1 className='mb-5'>Shorts from The Anuyog Institute</h1>
//                 <div className="videos-grid">
//                     {shorts.map(short => (
//                         <div className="video-item" key={short.id.videoId}>
//                             <div className="drope-image">
//                                 <iframe
//                                     width="100%"
//                                     height="315"
//                                     src={`https://www.youtube.com/embed/${short.id.videoId}`}
//                                     title="YouTube short video player"
//                                     frameBorder="0"
//                                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//                                     referrerPolicy="strict-origin-when-cross-origin"
//                                     allowFullScreen
//                                 ></iframe>
//                             </div>
//                             <div className="drope-text vidio-custom">
//                                 <h3>{short.snippet.title}</h3>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </>
//         ) : activeTab === 'playlists' ? (
//             <>
//                 <h1 className='mb-5'>Playlists from The Anuyog Institute</h1>
//                 <div className="videos-grid">
//                     {playlists.map(playlist => (
//                         <div className="video-item" key={playlist.id}>
//                             <div className="drope-image">
//                                 <iframe
//                                     width="100%"
//                                     height="315"
//                                     src={`https://www.youtube.com/embed/?listType=playlist&list=${playlist.id}`}
//                                     title="YouTube playlist player"
//                                     frameBorder="0"
//                                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//                                     referrerPolicy="strict-origin-when-cross-origin"
//                                     allowFullScreen
//                                 ></iframe>
//                             </div>
//                             <div className="drope-text vidio-custom">
//                                 <h3>{playlist.snippet.title}</h3>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </>
//         // ) : activeTab === 'communityPosts' ? (
//         //     <>
//         //         <h1 className='mb-5'>Community Posts from The Anuyog Institute</h1>
//         //         <div className="videos-grid">
//         //             {communityPosts.map(post => (
//         //                 <div className="video-item" key={post.id}>
//         //                     <div className="drope-image">
//         //                         <img src={post.snippet.thumbnails.medium.url} alt={post.snippet.title} />
//         //                     </div>
//         //                     <div className="drope-text vidio-custom">
//         //                         <h3>{post.snippet.title}</h3>
//         //                     </div>
//         //                 </div>
//         //             ))}
//         //         </div>
//         //     </>
//         ) : null}
//     </div>
// </section>  


//         </>
//     )
// }

// export default Vidieo

import React, { useEffect, useState } from 'react';
import '../vidio.css';
import logo from '../logo.png';
import vid from '../vid.png';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import fb from '../fb.png'
import ig from '../ig.png'
import youtube from '../you.png'
import InfiniteScroll from 'react-infinite-scroll-component';

function Vidieo() {

    const [videos, setVideos] = useState([]);
    const [shorts, setShorts] = useState([]);
    const [playlists, setPlaylists] = useState([]);
    const [activeTab, setActiveTab] = useState('home');
    const [videoPageToken, setVideoPageToken] = useState(null);  // To handle pagination for videos
    const [shortsPageToken, setShortsPageToken] = useState(null); // To handle pagination for shorts

    const API_KEY = 'AIzaSyDbCHy7ggFzp8dk7E6gI3MMID12rXyfe0Q';
    const CHANNEL_ID = 'UCziUYu9aahvyE251nJHwhMg';

    useEffect(() => {
        AOS.init({ duration: 1500, easing: 'ease-in-out' });
        fetchVideos();
        fetchShorts();
        fetchPlaylists();
    }, []);

    const fetchVideos = async (pageToken = '') => {
        try {
            const { data: playlistData } = await axios.get(`https://www.googleapis.com/youtube/v3/channels?part=contentDetails&id=${CHANNEL_ID}&key=${API_KEY}`);
            const uploadsPlaylistId = playlistData.items[0].contentDetails.relatedPlaylists.uploads;

            const { data: videosData } = await axios.get(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${uploadsPlaylistId}&key=${API_KEY}&maxResults=10&pageToken=${pageToken}`);

            setVideos(prevVideos => [...prevVideos, ...videosData.items]);
            setVideoPageToken(videosData.nextPageToken); // Set the next page token for future fetches
        } catch (error) {
            console.error("Error fetching videos:", error);
        }
    };

    const fetchShorts = async (pageToken = '') => {
        let allShorts = [];
        try {
            const { data: shortsData } = await axios.get(
                `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${CHANNEL_ID}&type=video&videoDuration=short&key=${API_KEY}&maxResults=10&pageToken=${pageToken}`
            );

            allShorts = shortsData.items;
            setShorts(prevShorts => [...prevShorts, ...allShorts]);
            setShortsPageToken(shortsData.nextPageToken); // Set the next page token for future fetches
        } catch (error) {
            console.error("Error fetching shorts:", error);
        }
    };

    const fetchPlaylists = async () => {
        let allPlaylists = [];
        let nextPageToken = '';

        try {
            do {
                const { data: playlistsData } = await axios.get(
                    `https://www.googleapis.com/youtube/v3/playlists?part=snippet&channelId=${CHANNEL_ID}&key=${API_KEY}&maxResults=50&pageToken=${nextPageToken}`
                );

                allPlaylists = [...allPlaylists, ...playlistsData.items];
                nextPageToken = playlistsData.nextPageToken;
            } while (nextPageToken);

            setPlaylists(allPlaylists);
        } catch (error) {
            console.error("Error fetching playlists:", error);
        }
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            {/* Banner and social section */}
            <section className="vidio-banner-section">
                <div className="vidio-banner">
                    <img src={vid} alt="" />
                    <h1 className="consultancy-heading" style={{ width: "100%", lineHeight: "1.2", textAlign: "center" }}>
                        Discover and develop a true understanding of a balanced, natural lifestyle through expert-reviewed content.
                    </h1>
                </div>
            </section>

            {/* Social media links */}
            <section className="head-section section-gapping">
                <div className="container">
                    <h1 className="mb-5"><strong>The Anuyog Institute</strong></h1>
                    <div className="head">
                        <div className="head-detail">
                            <div className="head-logo">
                                <img src={logo} alt="" />
                            </div>
                            <div className="head-text">
                                <h3>Subscribe on your favorite platform</h3>
                                <ul className="social">
                                    <a href="https://www.youtube.com/@theanuyoginstitute"><li><img src={youtube} alt="" /></li></a>
                                    <a href="https://www.facebook.com/theanuyoginstitute/"><li><img src={fb} alt="" /></li></a>
                                    <a href="https://www.instagram.com/theanuyoginstitute/"><li><img src={ig} alt="" /></li></a>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Tabs Section */}
            <section className="tabs-section section-gapping">
                <div className="container">
                    <div className="tabs">
                        <button className={activeTab === 'home' ? 'active' : ''} onClick={() => handleTabClick('home')}>Home</button>
                        <button className={activeTab === 'videos' ? 'active' : ''} onClick={() => handleTabClick('videos')}>Videos</button>
                        <button className={activeTab === 'shorts' ? 'active' : ''} onClick={() => handleTabClick('shorts')}>Shorts</button>
                        <button className={activeTab === 'playlists' ? 'active' : ''} onClick={() => handleTabClick('playlists')}>Playlists</button>
                    </div>
                </div>
            </section>

            {/* Content Section */}
            <section className={activeTab === 'home' ? 'home-section section-gapping' :
                activeTab === 'videos' ? 'videos-section section-gapping' :
                    activeTab === 'shorts' ? 'shorts-section section-gapping' :
                        activeTab === 'playlists' ? 'playlists-section section-gapping' : ''}>
                <div className="container">
                    {activeTab === 'home' ? (
                        <>
                            <h1 className="mb-5 offering-title">All Videos from The Anuyog Institute</h1>
                            <InfiniteScroll
                                dataLength={videos.length} // current items
                                next={() => fetchVideos(videoPageToken)} // fetch next page
                                hasMore={!!videoPageToken} // check if more items are available
                                loader={<h4>Loading...</h4>}
                            >
                                <div className="videos-grid">
                                    {[...videos, ...shorts].map((item, index) => (
                                        <div className="video-item" key={item.id.videoId || item.id}>
                                            <div className="drope-image">
                                                <iframe
                                                    width="100%"
                                                    height="315"
                                                    src={`https://www.youtube.com/embed/${item.id.videoId || item.id}`}
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    referrerPolicy="strict-origin-when-cross-origin"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                            <div className="drope-text vidio-custom">
                                                <h3>{item.snippet.title}</h3>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </InfiniteScroll>
                        </>
                    ) : activeTab === 'videos' ? (
                        <>
                            <h1 className="mb-5">Videos from The Anuyog Institute</h1>
                            <InfiniteScroll
                                dataLength={videos.length}
                                next={() => fetchVideos(videoPageToken)}
                                hasMore={!!videoPageToken}
                                loader={<h4>Loading...</h4>}
                            >
                                <div className="videos-grid">
                                    {videos.map(video => (
                                        <div className="video-item" key={video.id}>
                                            <div className="drope-image">
                                                <iframe
                                                    width="100%"
                                                    height="315"
                                                    src={`https://www.youtube.com/embed/${video.snippet.resourceId.videoId}`}
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    referrerPolicy="strict-origin-when-cross-origin"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                            <div className="drope-text vidio-custom">
                                                <h3>{video.snippet.title}</h3>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </InfiniteScroll>
                        </>
                    ) : activeTab === 'shorts' ? (
                        <>
                            <h1 className="mb-5">Shorts</h1>
                            <InfiniteScroll
                                dataLength={shorts.length}
                                next={() => fetchShorts(shortsPageToken)}
                                hasMore={!!shortsPageToken}
                                loader={<h4>Loading...</h4>}
                            >
                                <div className="videos-grid">
                                    {shorts.map(short => (
                                        <div className="video-item" key={short.id}>
                                            <div className="drope-image">
                                                <iframe
                                                    width="100%"
                                                    height="315"
                                                    src={`https://www.youtube.com/embed/${short.id.videoId}`}
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    referrerPolicy="strict-origin-when-cross-origin"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                            <div className="drope-text vidio-custom">
                                                <h3>{short.snippet.title}</h3>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </InfiniteScroll>
                        </>
                    ) : activeTab === 'playlists' ? (
                        <>
                            <h1 className='mb-5'>Playlists from The Anuyog Institute</h1>
                            <div className="videos-grid">
                                {playlists.map(playlist => (
                                    <div className="video-item" key={playlist.id}>
                                        <div className="drope-image">
                                            <iframe
                                                width="100%"
                                                height="315"
                                                src={`https://www.youtube.com/embed/?listType=playlist&list=${playlist.id}`}
                                                title="YouTube playlist player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                referrerPolicy="strict-origin-when-cross-origin"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                        <div className="drope-text vidio-custom">
                                            <h3>{playlist.snippet.title}</h3>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : null}
                </div>
            </section>
        </>
    );
}

export default Vidieo;
