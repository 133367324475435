import '../footer.css'
import React from 'react'
import fb from '../fb.png'
import ig from '../ig.png'
import youtube from '../you.png'
// import twiter from '../twi.png'
import linkdin from '../lin.png'
import logo from '../logo.png'
import { NavLink } from 'react-router-dom';

function Footer() {
  return (
    <div>
      <div className="footer-section">
        <div className="container">
          <div className="footer">
            <div className="logo-image">
              <img src={logo} alt="" />
            </div>
            <div className="footer-all">
              <div className="footer-bottom">

                <div className=" text-start footer-info">
                  <ul>
                    <h1 className='text-dark'>COMPANY</h1>
                    <li>
                      <NavLink
                        to="/consultancy"
                        className={({ isActive }) => (isActive ? 'main-active' : '')}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        Consultancy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/product"
                        className={({ isActive }) => (isActive ? 'main-active' : '')}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        Product
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/workshop"
                        className={({ isActive }) => (isActive ? 'main-active' : '')}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        Institute
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/disclaimer"  
                        className={({ isActive }) => (isActive ? 'main-active' : '')}
                        onClick={() => {
                          window.scrollTo(0, 0); 
                        }}
                      >
                        Disclosures
                      </NavLink>
                    </li>

                  </ul>
                  <ul>
                    <h1 className='text-dark'>PRODUCTS</h1>
                    <li>
                      <NavLink
                        to="/product"
                        className={({ isActive }) => (isActive ? 'main-active' : '')}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        Amrit Pey
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/product"
                        className={({ isActive }) => (isActive ? 'main-active' : '')}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        Khand
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/product"
                        className={({ isActive }) => (isActive ? 'main-active' : '')}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        Jaggery
                      </NavLink>
                    </li>
                  </ul>
                  <ul>
                    <h1 className='text-dark'>You’ve a question?</h1>

                    <li>Contact Us on: <br /> +91 789-230-7418</li>
                    <li>Or send us an email: theanuyoginstitute@gmail.com</li>

                  </ul>
                  <div className="social-media">
                    <h5 >Follow Us</h5>
                    <ul className='social'>
                      <a href="https://www.youtube.com/@theanuyoginstitute"><li><img src={youtube} alt="" /></li></a>
                      <a href="https://www.facebook.com/theanuyoginstitute/"><li><img src={fb} alt="" /></li></a>
                      <a href="https://www.instagram.com/theanuyoginstitute/"> <li><img src={ig} alt="" /></li></a>
                      <li><a href="/"><img src={linkdin} alt="" /></a></li>
                    </ul>

                  </div>
                </div>
              </div>
              <div className="footer-top">

                <div className="footer-address">
                  <div className="footer-add-text">
                    <h5 className='text-start mb-2 mt-4 '>@ANUYOG 2024 All rights reserved. </h5>
                    <h5 className='text-start mb-2 mt-4 '><strong>Disclaimer:</strong> We will not be providing any medical advice </h5>
                  </div>
                  <div className="footer-add-text">
                    <h6 className='text-start mb-2 mt-4 '>Designed and Developed by EFSOULS </h6>
                    <p><a href="https://efsouls.com/">www.efsouls.com</a></p>
                    <p>+91 988-677-1395</p>
                    
                  </div>


                </div>

                {/* <div className="logo-image">
  <img src={logo} alt="" />
</div> */}


                {/* <div className="social-media">
  <ul className='social'>
    <li><a href="https://www.facebook.com/theanuyoginstitute/"><img src={fb} alt="" /></a></li>
    <li><a href="https://www.instagram.com/theanuyoginstitute/"><img src={ig} alt="" /></a></li>
    <li><a href="https://www.youtube.com/@theanuyoginstitute"><img src={youtube} alt="" /></a></li>
    <li><a href="/"><img src={twiter} alt="" /></a></li>
    <li><a href="/"><img src={linkdin} alt="" /></a></li>
  </ul>
</div> */}

              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}

export default Footer
