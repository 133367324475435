import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import '../home.css';
// import AOS from 'aos';
import 'aos/dist/aos.css';
import banner2 from '../banner2.png'
import banner3 from '../banner3.jpeg'
import banner4 from '../banner4.jpg'
import '../consultancy.css'
import Contact from './Contact'
import { NavLink } from 'react-router-dom';
import Offering from './Offering';
import { motion } from 'framer-motion';

function Home() {
  const points = [
    {
      text: "Rooted in Naturopathy & Jain principles",
      icon: "/images/naturopathy.png",
    },
    {
      text: "Expert-driven & Science-backed",
      icon: "/images/science.png",
    },
    {
      text: "Powered by Data, AI & Analytics",
      icon: "/images/ai.png",
    },
    {
      text: "Empowering, not just guiding your wellness journey",
      icon: "/images/journey.png",
    },
    {
      text: "Advocating Sustainability & Eco-friendly choices",
      icon: "/images/landscape.png",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3 // Delays between each child animation
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
  };



  return (
    <>



      <section className="banner-section ">
        <Swiper
          modules={[Navigation, Pagination, Autoplay, EffectFade]}
          navigation={true}
          pagination={{ clickable: true }}
          loop={true}
          autoplay={{ delay: 2000, }}
          effect="fade"
          fadeEffect={{ crossFade: true }}
          speed={8000}
          className="swiper-container"
          style={{ height: '100vh' }}
        >

          <SwiperSlide>
            <img src={banner2} alt="Banner 4" className="carousel-image" style={{ borderRadius: '0px' }} />
            <div className="banner-text">
              <h1>“All we have to decide is what to do with the time that is given to us"
              </h1>
              <div className="banner-button">
                <div className="how">
                  <NavLink
                    to="/schedule"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <button type="button" className="btn">Book Free Consultancy</button>
                  </NavLink>
                </div>
                <NavLink
                  to="/contact"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <button type="button" className="btn">Contact Us</button>
                </NavLink>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={banner3} alt="Banner 2" className="carousel-image" style={{ borderRadius: '0px' }} />
            <div className="banner-text">
              <h1 translate="no">"उदय के समय नहीं, बंध के समय चेतो"</h1>
              <div className="banner-button">
                <div className="how">
                  <NavLink
                    to="/schedule"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <button type="button" className="btn">Book Free Consultancy</button>
                  </NavLink>
                </div>
                <NavLink
                  to="/contact"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <button type="button" className="btn">Contact Us</button>
                </NavLink>
              </div>


            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={banner4} alt="Banner 3" className="carousel-image" style={{ borderRadius: '0px' }} />
            <div className="banner-text">
              <h1>"IT IS OUR CHOICES THAT SHOW WHAT WE TRULY ARE, FAR MORE THAN OUR ABILITIES"</h1>
              <div className="banner-button">
                <div className="how">
                  <NavLink
                    to="/schedule"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <button type="button" className="btn">Book Free Consultancy</button>
                  </NavLink>
                </div>
                <NavLink
                  to="/contact"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <button type="button" className="btn">Contact Us</button>
                </NavLink>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>





      <section className='intro-section'>
        <div className="container">
          <div className="work-text" >
            <h3
              className='fonts text-center text-dark mb-4 fw-bold offering-title'
              style={{ maxWidth: '730px', margin: '0 auto' }}
            >Our mission is to
              <strong style={{ color: '#9D3B24' }}> elevate your holistic health and well-being</strong> by empowering your journey to a

              <strong style={{ color: '#9D3B24' }}> natural lifestyle.</strong>
            </h3>
          </div>
        </div>
      </section>

      <section className="why-anuyog-section">
        <div className="container">
          <h1 className="section-title">Why Anuyog?</h1>
          <motion.div
            className="why-anuyog-list"
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            style={{ marginTop: "20px" }}
          >
            {points.map((point, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                className="why-anuyog-point"
              >
                <img
                  src={point.icon}
                  alt={`Icon for ${point.text}`}
                  style={{
                    width: '60px',
                    height: '60px',
                    marginBottom: '10px',
                    filter: 'brightness(100%)',
                  }}
                />

                {point.text}
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>


      <Offering />
      <Contact showBanner={false} />


    </>
  );
}

export default Home;