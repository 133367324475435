import React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../expert.css';

const expertData = {
    "dr-pragati-jain": {
        name: "Dr. Pragati Jain",
        qualifications: "Founder, Anuyog",
        role: "Ph.D in Computational Neuroscience",
        dutey:"15 years of experience in Artificial Intelligence",
        description: `Dr. Pragati Jain is passionate about natural living, blending her knowledge of Naturopathy, Jainism, and Modern Science to provide holistic well-being solutions. With a Ph.D. in Computational Neuroscience, she brings a scientific lens to promoting mindful, sustainable living. Currently pursuing a Diploma in Naturopathy and Yoga (NDDY), she is committed to deepening her expertise in natural health.`,
        description2: `Her professional journey of more than 15 years is marked by a diverse range of experiences across academics, start-ups, and multi-national companies. She has worked extensively in industries such as Reinsurance, Retail Analytics, Sports Analytics, and Software Company where she applied her analytical skills to solve complex problems and drive business impact. Now, through her company Anuyog, she merges natural living with data-driven insights to offer personalized wellness solutions tailored to individual needs.`,
        description3: `Her vision is to inspire individuals to make informed lifestyle choices that align with nature, fostering a balanced, harmonious life through personalized wellness strategies.`,
        basedIn: `Scientific Insights in Wellness `,
        based: `Wellness in Jainism`,
        basedtext: `Building Anuyog`,
        image: '/images/prgati_jain.png',
        img: '/images/elaborate_prgati.png',
        publications: [
            {
                title: "Jain P, and Bhalla, U.S",
                journal1: "Transcription control pathways decode synaptic inputs into diverse mRNA expression profiles. PLoS ONE, 2014 May 1;9(5):e95154."
            },
            {
                title: "Jain P, and Bhalla, U.S",
                journal2: "Signaling logic of activity-triggered dendritic protein synthesis: an mTOR gate but not a feedback switch. PLoS Computational Biology, 2009 Feb;5(2):e1000287"
            }
        ]
    },
   "dr-suresh-jain": {
        name: "Dr. Suresh Jain",
        qualifications: "Certified Naturopathy Doctor",
        role: "25 years of experience in Natural Healing",
        description: "Dr. Suresh Jain, a certified Naturopathy Doctor from Bhind, Madhya Pradesh, has over 25 years of experience in natural healing. Throughout his career, he has treated more than 1,000 patients using Naturopathy techniques over the last 20 years, helping them achieve healthier, medicine-free lives. He is passionate about promoting veganism, organizing multiple vegan camps where he has served thousands of people with plant-based meals. ",
        description2: "He has also led several in-person Naturopathy workshops, with a primary focus on helping individuals live medicine-free lives through natural practices. In addition to his workshops, Dr. Jain is an avid public speaker, delivering insightful lectures on Naturopathy, which can be accessed on his YouTube playlist. His contributions to the field of holistic health have positively impacted the lives of countless people, making him a trusted voice in the natural wellness community.",
        basedIn: "Diet Planning",
        based: "Food & Nutrition",
        basedtext: "Healing with Nature’s Five Elements",
        special:"In-Person Naturopathy Workshops",
        sp:"Public Speaking on Natural Wellness",
        image: '/images/dsjain.png',
        publications: [],
        videoEmbed: "<iframe width='560' height='315' src='https://www.youtube.com/embed/EfeXie23gZo' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"   
    },
    "dr-ashish-jain": {
        name: "Yogacharya Dr. Ashish Jain",
        qualifications: "Certified Yoga Teacher",
        role: "10 years of experience in teaching Yoga",
        dutey:"Head of Balak Vidyarthi Grah, Amayan Bhind",
        description: "Yogacharya Dr. Ashish Jain is a certified yoga teacher dedicated to sharing the ancient wisdom of yoga for holistic well-being. He hails from Singodi, near Chhindwara, M.P., and holds a BA in Geography and an MA in English. His spiritual journey deepened after spending two months with Brahmachari Pandit Sumat Prakash Ji, followed by four years at Brahmachari Ashram, Amayan Bhind, under the guidance of Shri Ravindra Pandit Ji, where he took vows of Brahmacharya.",
        description2: "Dr. Ashish now leads the Balak Vidyarthi Grah at Amayan Bhind, fostering a disciplined, spiritually enriching environment aligned with Jain practices and the traditional Gurukul system. His guidance encourages students to live mindfully, embracing simplicity, non-violence, and holistic wellness.",
        description3: "With over 10 years of yoga practice, Dr. Ashish holds a Yoga certification from the Paramedical Council, Jabalpur, and has studied yoga under Baba Ramdev. He completed his Diploma in Naturopathy and Yoga (NDDY) in 2023. He has taught yoga to thousands and aspires to help individuals achieve health naturally, free from reliance on medicines.",
        basedIn: "Holistic Yog for Mind-Body Wellness",
        based: "Guiding Youth in Traditional, Spiritual Practices",
        basedtext: "Exercises to Enhance Mental Clarity and Health",
        image: '/images/dajain.jpeg',
        publications: []
    },
   "rohit-jain": {
        name: "Rohit Jain",
        qualifications: "Founder, Banyan Roots Organics Pvt. Ltd.",
        role: "Sustainable Mud House Consultant",
        dutey:"14 years of experience as an Organic Farmpreneur",
        description: "Rohit Jain, co-founder of Banyan Roots, has over 14 years of experience in organic food and farming, with a focus on reviving traditional, sustainable agricultural practices and food habits. His work began with tribal farmers in Rajasthan, where he encouraged the use of indigenous seeds and ecological farming methods to protect soil health and biodiversity. Rohit advocates for a return to natural farming processes that avoid harmful chemicals, ultimately leading to healthier food.Through Banyan Roots, he connects rural farmers with urban consumers, promoting fair trade and encouraging the consumption of organic, millet-based, and chemical-free foods. His dedication to sustainability is also linked to the global Slow Food movement, which values traditional food cultures, local ecosystems, and a reduced environmental footprint.",
        description2: "Rohit’s efforts not only support individual health but also contribute to a more sustainable planet. He remains committed to promoting sustainable agricultural practices that benefit both communities and the environment, ensuring a positive impact for future generations.In addition to his work in agriculture, Rohit Jain is a strong advocate for sustainable living, with extensive experience in building eco-friendly mud houses. His journey into sustainable architecture began with a deep respect for nature and a desire to reconnect people with environmentally conscious living spaces. Using locally sourced materials like mud, bamboo, and stone, Rohit’s approach reduces the environmental footprint while creating durable and climate-responsive homes. These mud houses are naturally insulated, keeping interiors cool in the summer and warm in the winter, making them energy-efficient.",
        description3: "Rohit’s passion for tribal living and indigenous construction techniques is reflected in his commitment to sustainable development. By building mud houses, he is not only preserving ancient craftsmanship but also providing an alternative to modern construction methods that often harm the environment. Through Banyan Roots, Rohit promotes a lifestyle that respects natural resources, reduces waste, and fosters a harmonious connection between humans and the earth. His expertise in sustainable architecture is a testament to his dedication to creating eco-friendly, affordable housing solutions for communities.",
        basedIn: "Organic Farming",
        based: "Ecological Farming",
        basedtext: "Eco-Friendly Construction",
        special:"Traditional & Sustainable Agriculture Practices",
        sp:"vertical farming",
        image: '/images/drjain.jpeg',
        publications: []
    },
    "sunita-jain": {
        name: "Yogacharya Sunita Jain",
        qualifications: "Certified Yoga Teacher",
        role: "20 years of experience in Acupressure, Sujok, Magnet Therapy, Yoga, Pranayama & Naturopathy",
        description: "Sunita Jain is a passionate practitioner of natural healing who has spent over 20 years mastering therapies like acupressure, Sujok, magnet therapy, yoga, pranayama, and naturopathy. Through her dedication, she managed to heal herself from HLA-B27, an autoimmune condition once considered incurable by major institutions. This journey of self-healing only deepened her commitment to natural wellness. When her husband, Dev, suffered a severe heart attack and faced a 95% arterial blockage, Sunita embraced natural treatments once again, guiding his recovery without surgery. Today, Dev leads a healthy, active life, a testament to the power of nature-based therapies. ",
        description2: "Sunita’s journey is more than a story of overcoming odds; it’s a message of hope, resilience, and faith in the body’s capacity to heal with the right natural practices. Sunita expanded her commitment to wellness by guiding others through similar holistic practices. Using nature’s resources, lifestyle adjustments, and a positive mindset, she’s inspired thousands to find healing and balance. Her story shows that dedicated lifestyle changes can make a lasting impact even with complex health challenges.",
        basedIn: "Acupressure",
        based: "Magnet Therapy",
        basedtext: "Sujok Therapy ",
        special:"Natural Healing",
        sp:"Yog and Pranayama",
        image: '/images/sunitajain.jpeg',
        publications: []
    },
};

function Expert() {
    const { slug } = useParams(); 
    const navigate = useNavigate();
    const [expert, setExpert] = useState(null);
    const expertSlugs = Object.keys(expertData);

    useEffect(() => {
        setExpert(expertData[slug]);
    }, [slug]);

    const handleNext = () => {
        const currentIndex = expertSlugs.indexOf(slug);
        const nextIndex = (currentIndex + 1) % expertSlugs.length;
        const nextSlug = expertSlugs[nextIndex];
        navigate(`/expert/${nextSlug}`);
        window.scrollTo(0, 0);
    };

    const handlePrevious = () => {
        const currentIndex = expertSlugs.indexOf(slug);
        const previousIndex = (currentIndex - 1 + expertSlugs.length) % expertSlugs.length;
        const previousSlug = expertSlugs[previousIndex];
        navigate(`/expert/${previousSlug}`);
        window.scrollTo(0, 0);
    };
    if (!expert) {
        return <p>Expert not found</p>;
    }

    return (
        <div>
            <section className='top-section'>
                <div className="container">
                    <div className="top">
                        <div className="top-image">
                            <img src={expert.image} alt={expert.name} />
                        </div>
                        <div className="top-text">
                                <h4 className='text-start fw-bold mt-3'>{expert.name}</h4>
                                <p>{expert.qualifications}</p>
                                <p><strong>{expert.role}</strong></p>
                                <p>{expert.dutey}</p>
                            </div>

                        <div className="basedin-text">

                            <h4 className='mb-3 fw-bold'>Specialization Areas</h4>
                            <p className='fw-bold'>{expert.basedIn}</p>
                            <p className='fw-bold'>{expert.based}</p>
                            <p className='fw-bold'>{expert.basedtext}</p>
                            <p className='fw-bold'>{expert.special}</p>
                            <p className='fw-bold'>{expert.sp}</p>

                        </div>
                    </div>

                </div>
            </section>

            <section className='biography-section'>
                <div className="container">
                    <div className="biography row">
                        <div className="bio-text col-md-6">
                            <h2 className='fw-bold text-start mb-4'>Biography</h2>
                            <p>{expert.description}</p>
                            <p>{expert.description2}</p>
                            <p>{expert.description3}</p>

                            {expert.videoEmbed && (
                                <div className="video-embed">
                                    <h2 className='fw-bold text-start mb-4 mt-5'>Watch Dr. Suresh Jain's YouTube Playlist:</h2>
                                    <div dangerouslySetInnerHTML={{ __html: expert.videoEmbed }} />
                                </div>
                            )}

                            {expert.publications.length > 0 && (
                                <div className="publications">
                                    <h2 className='fw-bold text-start mb-4 mt-5'>Publications:</h2>
                                    <ul>
                                        {expert.publications.map((pub, index) => (
                                            <li key={index}>
                                                <h3 className='fw-bold'>{pub.title}</h3>
                                                <p><a href="https://pubmed.ncbi.nlm.nih.gov/24787753/">{pub.journal1}</a></p>
                                                <p><a href="https://pubmed.ncbi.nlm.nih.gov/19242559/">{pub.journal2}</a></p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                
                            )}

                            
                           


                        </div>
                        <div className="col-md-6">
                        <div className="elobrate-image">
                            <img src={expert.img} alt="" />
                        </div>
                        </div>

                    </div>
                </div>
            </section>

            <div className="expert-button mb-5">
                <button type='button' onClick={handlePrevious} className='btn mt-3 me-md-5'>
                    Previous Expert
                </button>
                <button type='button' onClick={handleNext} className='btn mt-3'>
                    Next Expert
                </button>


            </div>
        </div>
    );
}

export default Expert;
